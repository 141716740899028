import { __Front as __ } from '@Shared/lib';

import type { eventTimeInfo } from '@/_types/Shared/advanced-block-page/EventSingle';
import type { ContainerTypeEnums, ContentClass, IconTypeEnums } from '@/_types/Shared/Common';
import type { QueryPost } from '@/_types/Shared/Query';
import {
	DateIcon,
	DateIconFilled,
	LocalizationIcon,
	LocalizationIconFilled,
	PersonIcon,
	PersonIconFilled,
	PriceIcon,
	PriceIconFilled,
	SiteIcon,
	SiteIconFilled,
	TagIcon,
	TagIconFilled,
} from '@/views/Shared/Icons';

type EventInfoProps = {
	post?: QueryPost;
	singleHeadingFontClassName?: ContentClass;
	dates?: eventTimeInfo[];
	iconType?: IconTypeEnums;
	eventsInfoContainerType?: ContainerTypeEnums;
	containerStyle?: string;
	displayOrganizer?: boolean;
	displayDate?: boolean;
	displayTag?: boolean;
	displayPrice?: boolean;
	displayPlace?: boolean;
	displaySite?: boolean;
};

export default function EventsInfo(props: EventInfoProps) {
	const {
		post,
		dates,
		eventsInfoContainerType,
		containerStyle,
		singleHeadingFontClassName,
		iconType,
		displayDate,
		displayOrganizer,
		displayPlace,
		displayPrice,
		displayTag,
		displaySite,
	} = props;

	return (
		<>
			{/* Events Info */}
			{post?.price || dates || post?.organizer || post?.venue || post?.tag ? (
				<div
					className={`flex flex-col gap-6 tablet:grid tablet:grid-cols-2 ${containerStyle}`}
					data-variant-container={`info-${eventsInfoContainerType}`}
				>
					{displayDate && (
						<>
							{dates && dates?.length > 0 ? (
								<div>
									<p
										className={`mb-2 inline-flex items-center gap-2 tablet:mb-2.5 ${singleHeadingFontClassName?.text?.h1}`}
									>
										{iconType === 'outline' ? (
											<DateIcon className='text-minos-single-events-icon-color' />
										) : (
											<DateIconFilled className='text-minos-single-events-icon-color' />
										)}
										{dates.length > 0 ? __('Dates') : __('Date')}
									</p>
									<ul>
										{dates.map((date: eventTimeInfo, index: number) => {
											return (
												<li
													className={`mb-4 flex flex-col last:mb-0 ${singleHeadingFontClassName?.text?.p}`}
													key={index}
												>
													<DateSingle date={date} />
												</li>
											);
										})}
									</ul>
								</div>
							) : (
								''
							)}
						</>
					)}

					{displayPrice && (
						<>
							{post?.price && (
								<div>
									<p
										className={`mb-2 inline-flex items-center gap-2 tablet:mb-2.5 ${singleHeadingFontClassName?.text?.h1}`}
									>
										{iconType === 'outline' ? (
											<PriceIcon width='20' className='text-minos-single-events-icon-color' />
										) : (
											<PriceIconFilled width='20' className='text-minos-single-events-icon-color' />
										)}
										{__('Price')}
									</p>
									<p className={singleHeadingFontClassName?.text?.p}> {post?.price}</p>
								</div>
							)}
						</>
					)}

					{displayTag && (
						<>
							{post?.tag && post.tag.length > 0 ? (
								<div className='flex flex-col'>
									<p
										className={`mb-2 inline-flex items-center gap-2 tablet:mb-2.5 ${singleHeadingFontClassName?.text?.h1}`}
									>
										{iconType === 'outline' ? (
											<TagIcon width='22' className='text-minos-single-events-icon-color' />
										) : (
											<TagIconFilled width='22' className='text-minos-single-events-icon-color' />
										)}

										{post?.tag.length > 1 ? __('Categories') : __('Category')}
									</p>
									<ul className='inline-flex flex-wrap gap-x-2'>
										{post?.tag?.map((tag: string, index: number) => {
											return (
												<li
													key={index}
													className={`after:content-[','] last:after:content-[''] ${singleHeadingFontClassName?.text?.p}`}
												>
													{tag}
												</li>
											);
										})}
									</ul>
								</div>
							) : (
								''
							)}
						</>
					)}

					{displayOrganizer && (
						<>
							{post?.organizer && post?.organizer?.length > 0 ? (
								<div className='flex flex-col'>
									<p
										className={`mb-2 inline-flex items-center gap-2 tablet:mb-2.5 ${singleHeadingFontClassName?.text?.h1}`}
									>
										{iconType === 'outline' ? (
											<PersonIcon width='22' height='19' className='text-minos-single-events-icon-color' />
										) : (
											<PersonIconFilled
												width='22'
												height='19'
												className='text-minos-single-events-icon-color'
											/>
										)}

										{post?.organizer.length > 1 ? __('Organisers') : __('Organiser')}
									</p>

									<ul className='inline-flex flex-wrap gap-x-2'>
										{post?.organizer?.map((orga: string, index: number) => {
											return (
												<li
													key={index}
													className={`after:content-[','] last:after:content-[''] ${singleHeadingFontClassName?.text?.p}`}
												>
													{orga}
												</li>
											);
										})}
									</ul>
								</div>
							) : (
								''
							)}
						</>
					)}

					{displayPlace && (
						<>
							{post?.venue && post?.venue?.length > 0 ? (
								<div className='flex flex-col'>
									<p
										className={`mb-2 inline-flex items-center gap-2 tablet:mb-2.5 ${singleHeadingFontClassName?.text?.h1}`}
									>
										{iconType === 'outline' ? (
											<LocalizationIcon
												width='20'
												height='20'
												className='text-minos-single-events-icon-color'
											/>
										) : (
											<LocalizationIconFilled
												width='20'
												height='20'
												className='text-minos-single-events-icon-color'
											/>
										)}

										{post?.venue.length > 1 ? __('Venues') : __('Venue')}
									</p>
									<ul className='inline-flex flex-wrap gap-x-2'>
										{post?.venue?.map((orga: string, index: number) => {
											return (
												<li
													key={index}
													className={`inline-flex after:content-[','] last:after:content-[''] ${singleHeadingFontClassName?.text?.p}`}
												>
													{orga}
												</li>
											);
										})}
									</ul>
								</div>
							) : (
								''
							)}
						</>
					)}

					{displaySite && (
						<>
							{post?.link ? (
								<div className='flex flex-col'>
									<p
										className={`mb-2 inline-flex items-center gap-2 tablet:mb-2.5 ${singleHeadingFontClassName?.text?.h1}`}
									>
										{iconType === 'outline' ? (
											<SiteIcon width='20' className='text-minos-single-events-icon-color' />
										) : (
											<SiteIconFilled width='20' className='text-minos-single-events-icon-color' />
										)}
										{__('Site')}
									</p>
									<a href={post?.link} className={singleHeadingFontClassName?.text?.p}>
										{post?.link}
									</a>
								</div>
							) : (
								''
							)}
						</>
					)}
				</div>
			) : (
				''
			)}
		</>
	);
}

const DateSingle = ({ date }: { date: eventTimeInfo }) => {
	if (date.end_date_format && !date.all_day) {
		return (
			<>
				<p>
					{__('From')} <time dateTime={date.start_date}>{date.start_date_format}</time>
				</p>
				<p>
					{__('To')} <time dateTime={date.end_date}>{date.end_date_format}</time>
				</p>
			</>
		);
	} else if (date.end_date_format && date.start_hour_format && date.end_hour_format) {
		return (
			<>
				<p>
					{__('From')} <time dateTime={date.start_date}>{date.start_date_format}</time>
				</p>
				<p>
					{__('To')} <time dateTime={date.end_date}>{date.end_date_format}</time>
				</p>
				<p>
					{__('From')} <time dateTime={date.start_hour}>{date.start_hour_format}</time> {__('to')}{' '}
					<time dateTime={date.end_hour}>{date.end_hour_format}</time>
				</p>
			</>
		);
	} else if (date.end_date_format && date.start_hour_format) {
		return (
			<>
				<p>
					{__('From')} <time dateTime={date.start_date}>{date.start_date_format}</time>
				</p>
				<p>
					{__('To')} <time dateTime={date.end_date}>{date.end_date_format}</time>
				</p>
				<p>
					{__('At')} <time dateTime={date.start_hour}>{date.start_hour_format}</time>
				</p>
			</>
		);
	} else if (date.start_hour_format && date.end_hour_format) {
		return (
			<>
				<p>
					<time dateTime={date.start_date}>{date.start_date_format}</time>
				</p>
				<p>
					{__('From')} <time dateTime={date.start_hour}>{date.start_hour_format}</time> {__('to')}{' '}
					<time dateTime={date.end_hour}>{date.end_hour_format}</time>
				</p>
			</>
		);
	} else if (date.start_hour_format) {
		return (
			<>
				<p>
					<time dateTime={date.start_date}>{date.start_date_format}</time>
				</p>
				<p>
					{__('At')} <time dateTime={date.start_hour}>{date.start_hour_format}</time>
				</p>
			</>
		);
	} else {
		return (
			<>
				<p>
					<time dateTime={date.start_date}>{date.start_date_format}</time>
				</p>
			</>
		);
	}
};
