import type { PostType } from '@Types/Shared/Post';
import type { QueryType } from '@Types/Shared/Query';
import type { TermType } from '@Types/Shared/Taxonomy';

import { useEffect, useState } from 'react';

import apiFetch from '@wordpress/api-fetch';

import Layout from '@Shared/Layout/Layout';
import { __Front as __, parseURL } from '@Shared/lib';

import type { FaqsOptions } from '@/_types/Blocks/Faqs';
import type {
	FaqsFiltersChoices,
	FaqsListingOptions,
} from '@/_types/Shared/advanced-block-page/FaqListing';
import type { BtnOptions } from '@/_types/Shared/Button';
import type { SectionDecorationOptions } from '@/_types/Shared/Common';
import type { Options } from '@/_types/Shared/Options';
import type { MenusType, SiteType } from '@/_types/Shared/SiteDatas';
import { Bubble, Wave } from '@/views/Shared/Icons';
import type { Translation } from '@/views/Shared/Translations';
import { router } from '@inertiajs/react';

import CardsTypes from './components/CardsTypes';
import { Filters } from './components/Filters';
import { Pagination } from './components/Pagination';

type ListingFaqsProps = {
	post: PostType;
	types: TermType[];
	site: SiteType;
	menus: MenusType;
	minos: Options;
	translations: Translation[];
};

export type FiltersFaqsType = {
	types: TermType[];
	selectedTypes: string;
	setSelectedTypes: (e: string) => void;
	resetButtonStyle?: BtnOptions;
	listingFaqs: FaqsListingOptions;
};

type ViewProps = {
	sectionDecoration: SectionDecorationOptions;
	listingFaqs: FaqsListingOptions;
	blockFaqs: FaqsOptions;
	filtersSelection: FaqsFiltersChoices;
	containerStyle: string;
	marginHeading: string;
	headingStyle: string;
	paddingHeading: string;
	subHeadingStyle: string;
	results: number;
	posts: PostType[];
	paddingPagination: string;
	setPage: (value: number) => void;
	maxPage: number;
	page: number;
	FiltersProps: FiltersFaqsType;
};

export default function Listing(props: ListingFaqsProps) {
	const { types, site, menus, minos, translations } = props;

	const listingFaqs: FaqsListingOptions = { ...minos.layout.faqs.index };
	const blockFaqs: FaqsOptions = { ...minos.blocks.faqs.default };

	const filtersSelection: FaqsFiltersChoices = { ...minos.layout.faqs.index.listingFiltersChoices };

	const sectionDecoration: SectionDecorationOptions = { ...minos.layout.sectionDecoration };

	const params = parseURL(window.location.href);

	const [page, setPage] = useState<number>(params.pg ? params.pg : 1);
	const [posts, setPosts] = useState<PostType[]>([]);
	const [maxPage, setMaxPage] = useState<number>(1);
	const [results, setResults] = useState<number>(0);

	const [selectedTypes, setSelectedTypes] = useState<any>(params.type_faq ? params.type_faq : '');

	useEffect(() => {
		const _options: Promise<QueryType> = apiFetch({
			path: `/minos/v2/getposts`,
			method: 'POST',
			data: {
				args: {
					post_type: 'faq',
					posts_per_page: 9,
					paged: page,
					taxs: {
						type_faq: selectedTypes,
					},
				},
			},
		});
		_options.then((result) => {
			const q = result;
			setMaxPage(q.max_num_pages);
			setPosts(q.posts);
			setResults(q.found_posts);
		});
	}, [selectedTypes, page]);

	useEffect(() => {
		router.get(
			window.location.href,
			{
				type_faq: selectedTypes,
			},
			{
				preserveState: true,
				replace: true,
			}
		);
	}, [selectedTypes]);

	const FiltersProps = {
		types,
		setSelectedTypes,
		selectedTypes,
		listingFaqs,
		resetButtonStyle: listingFaqs.resetButtonStyle,
	};

	let containerStyle = '';

	if (listingFaqs.variant === 'block') {
		containerStyle = `rounded-minos-listing-faqs-container w-11/12 mx-auto relative z-[11] ${
			sectionDecoration?.isSectionDecorated && sectionDecoration?.type === 'wave'
				? 'mt-7 mb-12 tablet:mt-12 tablet:mb-24 desktop:mb-32'
				: 'my-7 tablet:my-12'
		}`;
	}

	let headingStyle = '';
	let subHeadingStyle = '';
	if (listingFaqs.headingVariant === 'center') {
		headingStyle = 'items-center';
		subHeadingStyle = 'flex-col items-center';
	} else if (listingFaqs.headingVariant === 'centerInline') {
		headingStyle = 'items-center';
		subHeadingStyle = 'flex-col tablet:w-full tablet:flex-row tablet:justify-between items-center';
	} else {
		headingStyle = 'items-start';
		subHeadingStyle = 'w-full justify-between items-center';
	}

	let paddingHeading = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		listingFaqs.variant === 'headline'
	) {
		paddingHeading = 'pt-12 pb-12 tablet:pb-28 desktop:pb-32';
	} else {
		paddingHeading = 'pt-12 pb-0 tablet:py-12';
	}

	let marginHeading = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		listingFaqs.variant === 'headline'
	) {
		marginHeading = 'mb-12 tablet:mb-24';
	} else {
		marginHeading = 'mb-12';
	}

	let paddingPagination = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		listingFaqs.variant != 'block'
	) {
		paddingPagination = 'pb-20 desktop:pb-36';
	} else {
		paddingPagination = 'pb-12';
	}

	const viewProps: ViewProps = {
		sectionDecoration,
		listingFaqs,
		containerStyle,
		marginHeading,
		headingStyle,
		paddingHeading,
		subHeadingStyle,
		results,
		filtersSelection,
		FiltersProps,
		posts,
		blockFaqs,
		paddingPagination,
		page,
		setPage,
		maxPage,
	};

	return (
		<Layout
			seo={null}
			site={site}
			menus={menus}
			minos={minos}
			translations={translations}
			className={
				listingFaqs.variant === 'block' ? 'relative bg-minos-listing-faqs-secondary-color' : ''
			}
		>
			<View {...viewProps} />
		</Layout>
	);
}

const View = (props: ViewProps) => {
	const {
		sectionDecoration,
		listingFaqs,
		containerStyle,
		marginHeading,
		headingStyle,
		paddingHeading,
		subHeadingStyle,
		results,
		filtersSelection,
		FiltersProps,
		posts,
		blockFaqs,
		paddingPagination,
		page,
		setPage,
		maxPage,
	} = props;
	return (
		<>
			{/* Bubble decoration */}
			{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
				<>
					<Bubble
						position='left'
						className='minos-decoration-bubble-left top-[-50px]'
						pathClassName='fill-minos-decoration-bubble-left'
					/>
				</>
			)}

			<article
				data-listing={`listing-layout-${listingFaqs.variant}`}
				className={`${containerStyle} bg-minos-listing-faqs-bg-color`}
			>
				<div
					data-heading={`listing-heading-${listingFaqs.headingVariant}`}
					className={`minos-grid relative  ${marginHeading}  ${
						listingFaqs.variant === 'headline' ? 'bg-minos-listing-faqs-secondary-color' : ''
					}`}
				>
					<div
						className={`${headingStyle} relative z-[11] col-start-2 col-end-14 flex flex-col  ${paddingHeading}`}
					>
						<h1 className='minos-listing-faqs-heading-title-font-style mb-4 text-4xl tablet:minos-listing-faqs-heading-title-font-style tablet:mb-8'>
							{listingFaqs.listingHeadingTitle ? listingFaqs.listingHeadingTitle : __('Listing FAQs')}
						</h1>

						<div className={`${subHeadingStyle} mb-4 flex flex-wrap gap-4 tablet:gap-8`}>
							{results && results > 0 ? (
								<p className='minos-listing-faqs-result-font-style text-base tablet:minos-listing-faqs-result-font-style'>
									{results} {__('results')}
								</p>
							) : (
								<p className='minos-listing-faqs-result-font-style text-base tablet:minos-listing-faqs-result-font-style'>
									{results} {__('result')}
								</p>
							)}

							{filtersSelection.types && <Filters {...FiltersProps} />}
						</div>
					</div>

					{/* Decoration wave visible only if variant listing === heading */}
					{listingFaqs.variant === 'headline' && (
						<>
							{sectionDecoration.isSectionDecorated && sectionDecoration.type === 'wave' ? (
								<Wave
									className='absolute bottom-0 left-0 z-10 h-[17px] w-screen overflow-x-hidden tablet:h-[40px] desktop:h-[70px]'
									pathClassName='fill-minos-listing-faqs-bg-color'
								/>
							) : (
								''
							)}
						</>
					)}
				</div>
				<section className='minos-grid relative'>
					<div className='z-[11] col-start-2 col-end-13 sm:col-end-14 tablet:col-span-12 tablet:col-start-2'>
						{posts && posts.length > 0 ? (
							<>
								<CardsTypes posts={posts} variant={blockFaqs.variant} />

								<Pagination
									className={paddingPagination}
									variant={listingFaqs.paginationVariant}
									page={page}
									setPage={setPage}
									maxPage={maxPage}
								/>
							</>
						) : (
							<div className='minos-listing-faqs-result-font-style col-span-12 my-10 text-center text-base tablet:minos-listing-faqs-result-font-style'>
								{__('No post found :( ...')}
							</div>
						)}
					</div>
				</section>
			</article>

			{listingFaqs.variant === 'block' && (
				<>
					{/* Bubble decoration */}
					{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
						<>
							<Bubble
								position='right'
								className='minos-decoration-bubble-right'
								pathClassName='fill-minos-decoration-bubble-right'
							/>
						</>
					)}
				</>
			)}
		</>
	);
};
