import Layout from '@Shared/Layout/Layout';

import type { TeamsOptions } from '@/_types/Blocks/Teams';
import type { SectionDecorationOptions } from '@/_types/Shared/Common';
import type { Options } from '@/_types/Shared/Options';
import type { QueryPost } from '@/_types/Shared/Query';
import type { SEO } from '@/_types/Shared/Seo';
import type { MenusType, SiteType } from '@/_types/Shared/SiteDatas';
import type { Translation } from '@/views/Shared/Translations';

import CardsTypes from './components/CardsTypes';

type SingleNewsType = {
	post: QueryPost;
	site: SiteType;
	menus: MenusType;
	minos: Options;
	translations: Translation[];
	seo: SEO;
};

export default function Single(props: SingleNewsType) {
	const { post, site, menus, minos, translations, seo } = props;

	const blockTeams: TeamsOptions = { ...minos.blocks.teams.default };
	const sectionDecoration: SectionDecorationOptions = { ...minos.layout.sectionDecoration };

	let padding = '';
	if (sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'wave') {
		padding = 'py-16 tablet:py-24 desktop:pb-40 desktop:pt-24';
	} else {
		padding = 'py-5 tablet:py-12 desktop:py-24';
	}

	return (
		<Layout seo={seo} site={site} translations={translations} menus={menus} minos={minos}>
			<>
				<article>
					<div data-aos='fade-up' className={`minos-grid bg-minos-single-teams-bg-color ${padding}`}>
						<div className='col-start-2 col-end-13 m-auto mb-0 sm:col-end-14 tablet:col-span-12 tablet:col-start-2 tablet:mb-5 desktop:col-span-8 desktop:col-start-4 desktop:mb-16 desktop:max-w-3xl'>
							<h1 className='minos-single-teams-heading-title-font-style text-4xl tablet:minos-single-teams-heading-title-font-style'>
								{post.lastname || post.firstname ? (
									<>
										{post.lastname} {post.firstname}
									</>
								) : (
									<>{post.post_title}</>
								)}
							</h1>
						</div>
						<div className='col-start-2 col-end-14 grid grid-cols-12 gap-8'>
							<CardsTypes
								post={post}
								variant={blockTeams.variant}
								imgType={blockTeams.imgType}
								displayTags={blockTeams.displayTags}
								displayDesc={blockTeams.displayDesc}
								displayMail={blockTeams.displayMail}
								displayPhone={blockTeams.displayPhone}
								displaySocials={blockTeams.displaySocials}
								socialColor={blockTeams.socialColor}
								socialColorHover={blockTeams.socialColorHover}
								single={true}
							/>
						</div>
					</div>
				</article>
			</>
		</Layout>
	);
}
