/* eslint-disable react/prop-types */
import Blocks from '@Shared/Blocks/Blocks';
import Layout from '@Shared/Layout/Layout';

import type { SEO } from '@/_types/Shared/Seo';
import type { MenusType, SiteType } from '@/_types/Shared/SiteDatas';

import type { Translation } from '../Shared/Translations';

type Props = {
	blocks: any;
	site: SiteType;
	menus: MenusType;
	minos: any;
	translations: Translation[];
	seo: SEO;
};

export default function Front({ blocks, site, menus, minos, translations, seo }: Props) {
	return (
		<Layout seo={seo} site={site} translations={translations} menus={menus} minos={minos}>
			<Blocks blocks={blocks} />
		</Layout>
	);
}
