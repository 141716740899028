import { useEffect } from 'react';

import { __Front as __ } from '@Shared/lib';

import type { ListingPaginationClass } from '@/_types/Shared/advanced-block-page/CommonListing';
import { router } from '@inertiajs/react';

import { ArrowBig } from '../../Icons';

type PaginationProps = {
	currentPage: number;
	maxPage: number;
	setPage: (e: number) => void;
	siblingCount?: number;
	paginationFontClassName?: ListingPaginationClass;
	className?: string;
};

export default function BgPagination(props: PaginationProps) {
	const {
		currentPage,
		setPage,
		maxPage,
		siblingCount = 1,
		paginationFontClassName,
		className = '', // used if sectiondecoration === wave and listing layout variant != block
	} = props;

	const items = paginationRange(currentPage, maxPage, siblingCount) as string[];

	useEffect(() => {
		router.get(
			window.location.href,
			{
				pg: currentPage,
			},
			{
				preserveState: true,
				replace: true,
			}
		);
	}, [currentPage]);

	return (
		<div className={`col-span-12 mt-12 flex justify-center ${className}`}>
			{maxPage > 1 && (
				<nav aria-label='Page navigation'>
					<ul
						className={` ${paginationFontClassName?.text} flex h-8 items-center gap-5 -space-x-px text-sm`}
					>
						<li className='group cursor-pointer'>
							<button
								onClick={() => setPage(currentPage - 1)}
								disabled={currentPage === 1}
								className={`${paginationFontClassName?.arrow} ms-0 flex h-8 items-center justify-center px-3 leading-tight duration-300 ease-in-out group-hover:transition-colors`}
							>
								<span className='sr-only'>{__('Previous')}</span>
								<ArrowBig
									width='30'
									className='-rotate-180 group-hover:-translate-x-2 group-hover:duration-300 group-hover:ease-in-out'
								/>
							</button>
						</li>
						{items?.map((item, index) => {
							const value = item === '...' ? null : (parseInt(item) as number);
							const Props = {
								number: item,
								value: value,
								isCurrent: item.toString() === currentPage.toString() ? true : false,
								changePage: setPage,
								paginationFontClassName: paginationFontClassName,
							};
							return <Item key={index} {...Props} />;
						})}
						<li className='group cursor-pointer'>
							<button
								onClick={() => setPage(currentPage + 1)}
								disabled={currentPage === maxPage}
								className={`${paginationFontClassName?.arrow} flex h-8 items-center justify-center px-3 leading-tight duration-300 ease-in-out group-hover:transition-colors`}
							>
								<span className='sr-only'>{__('Next')}</span>
								<ArrowBig
									width='30'
									className='group-hover:translate-x-2 group-hover:duration-300 group-hover:ease-in-out'
								/>
							</button>
						</li>
					</ul>
				</nav>
			)}
		</div>
	);
}
type ItemProps = {
	number?: number | string;
	isCurrent?: boolean;
	value: number | null;
	changePage: (value: number) => void;
	paginationFontClassName?: ListingPaginationClass;
};

const Item = (props: ItemProps) => {
	const { number = 1, isCurrent = false, value = 1, changePage, paginationFontClassName } = props;
	return (
		<li className='group cursor-pointer'>
			<button
				onClick={() => value && changePage(number as number)}
				className={`flex size-10 items-center justify-center px-3 ${
					isCurrent
						? ` ${paginationFontClassName?.bgCurrent} duration-300 ease-in-out group-hover:transition-colors ${paginationFontClassName?.text}`
						: ` duration-300 ease-in-out group-hover:transition-colors ${paginationFontClassName?.text} ${paginationFontClassName?.bg}`
				}`}
			>
				{number ? number : value}
			</button>
		</li>
	);
};

const range = (start: number, end: number) => {
	const length = end - start + 1;
	/*
		Create an array of certain length and set the elements within it from
	  start value to end value.
	*/
	return Array.from({ length }, (_, idx) => idx + start);
};

const paginationRange = (currentPage: number, totalPageCount: number, siblingCount: number) => {
	// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
	const totalPageNumbers = siblingCount + 5;

	/*
	  Case 1:
	  If the number of pages is less than the page numbers we want to show in our
	  paginationComponent, we return the range [1..totalPageCount]
	*/
	if (totalPageNumbers >= totalPageCount) {
		return range(1, totalPageCount);
	}

	/*
		Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
	*/
	const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
	const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

	/*
	  We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
	*/
	const shouldShowLeftDots = leftSiblingIndex > 2;
	const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

	const firstPageIndex = 1;
	const lastPageIndex = totalPageCount;
	const DOTS = '...';

	/*
		Case 2: No left dots to show, but rights dots to be shown
	*/
	if (!shouldShowLeftDots && shouldShowRightDots) {
		const leftItemCount = 3 + 2 * siblingCount;
		const leftRange = range(1, leftItemCount);

		return [...leftRange, DOTS, totalPageCount];
	}

	/*
		Case 3: No right dots to show, but left dots to be shown
	*/
	if (shouldShowLeftDots && !shouldShowRightDots) {
		const rightItemCount = 3 + 2 * siblingCount;
		const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
		return [firstPageIndex, DOTS, ...rightRange];
	}

	/*
		Case 4: Both left and right dots to be shown
	*/
	if (shouldShowLeftDots && shouldShowRightDots) {
		const middleRange = range(leftSiblingIndex, rightSiblingIndex);
		return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
	}
};
