import { useState } from 'react';

import { __Front as __ } from '@Shared/lib';

import { DateCard } from './DateCard';

export default function ViewCalendar({ calendar }: { calendar: any }) {
	const [isDayDate, setIsDayDate] = useState<string>('');

	return (
		<section className='col-start-2 col-end-14 flex flex-col py-12'>
			<ul className='my-6 grid grid-cols-7 gap-4 text-center desktop:text-left'>
				<li className=''>
					<span className='minos-listing-events-calendar-week-days text-sm desktop:hidden '>
						{__('M')}
					</span>
					<span className='minos-listing-events-calendar-week-days hidden desktop:block'>
						{__('Monday')}
					</span>
				</li>
				<li className=''>
					<span className='minos-listing-events-calendar-week-days text-sm desktop:hidden '>
						{__('T')}
					</span>
					<span className='minos-listing-events-calendar-week-days hidden desktop:block'>
						{__('Tuesday')}
					</span>
				</li>
				<li className=''>
					<span className='minos-listing-events-calendar-week-days text-sm desktop:hidden '>
						{__('W')}
					</span>
					<span className='minos-listing-events-calendar-week-days hidden desktop:block'>
						{__('Wednesday')}
					</span>
				</li>
				<li className=''>
					<span className='minos-listing-events-calendar-week-days text-sm desktop:hidden '>
						{__('Th')}
					</span>
					<span className='minos-listing-events-calendar-week-days hidden desktop:block'>
						{__('Thursday')}
					</span>
				</li>
				<li className=''>
					<span className='minos-listing-events-calendar-week-days text-sm desktop:hidden '>
						{__('F')}
					</span>
					<span className='minos-listing-events-calendar-week-days hidden desktop:block'>
						{__('Friday')}
					</span>
				</li>
				<li className=''>
					<span className='minos-listing-events-calendar-week-days text-sm desktop:hidden '>
						{__('S')}
					</span>
					<span className='minos-listing-events-calendar-week-days hidden desktop:block'>
						{__('Saturday')}
					</span>
				</li>
				<li className=''>
					<span className='minos-listing-events-calendar-week-days text-sm desktop:hidden '>
						{__('Su')}
					</span>
					<span className='minos-listing-events-calendar-week-days hidden desktop:block'>
						{__('Sunday')}
					</span>
				</li>
			</ul>

			<div className='grid grid-cols-7 gap-4'>
				{calendar ? (
					<>
						{Object.entries(calendar).map(([i, item]) => (
							<DateCard key={i} item={item} isDayDate={isDayDate} setIsDayDate={setIsDayDate} />
						))}
					</>
				) : (
					<div className='col-span-12 my-10 text-center text-2xl'>{__('No calendar :( ...')}</div>
				)}
			</div>
		</section>
	);
}
