import type { PostType } from '@Types/Shared/Post';
import type { QueryPost, QueryType } from '@Types/Shared/Query';
import type { TermType } from '@Types/Shared/Taxonomy';

import { useEffect, useState } from 'react';

import apiFetch from '@wordpress/api-fetch';

import Layout from '@Shared/Layout/Layout';
import { __Front as __, parseURL } from '@Shared/lib';

import type { TeamsOptions } from '@/_types/Blocks/Teams';
import type {
	TeamsFiltersChoices,
	TeamsListingOptions,
} from '@/_types/Shared/advanced-block-page/TeamListing';
import type { SectionDecorationOptions } from '@/_types/Shared/Common';
import type { Options } from '@/_types/Shared/Options';
import type { MenusType, SiteType } from '@/_types/Shared/SiteDatas';
import { Bubble, Wave } from '@/views/Shared/Icons';
import type { Translation } from '@/views/Shared/Translations';
import { router } from '@inertiajs/react';

import CardsTypes from './components/CardsTypes';
import { Filters } from './components/Filters';
import { Pagination } from './components/Pagination';

type ListingTeamsProps = {
	post: PostType;
	functions: TermType[];
	site: SiteType;
	menus: MenusType;
	minos: Options;
	translations: Translation[];
};

export type FiltersTeamsType = {
	functions: TermType[];
	selectedFunctions: string;
	setSelectedFunctions: (e: string) => void;
	listingTeams: TeamsListingOptions;
};

export default function Listing(props: ListingTeamsProps) {
	const { post, functions, site, menus, minos, translations } = props;

	const listingTeams: TeamsListingOptions = { ...minos.layout.teams.index };
	const blockTeams: TeamsOptions = { ...minos.blocks.teams.default };

	const filtersSelection: TeamsFiltersChoices = {
		...minos.layout.teams.index.listingFiltersChoices,
	};

	const sectionDecoration: SectionDecorationOptions = { ...minos.layout.sectionDecoration };

	const params = parseURL(window.location.href);

	const [page, setPage] = useState<number>(params.pg ? params.pg : 1);
	const [posts, setPosts] = useState<PostType[]>([]);
	const [maxPage, setMaxPage] = useState<number>(1);
	const [results, setResults] = useState<number>(0);

	const [selectedFunctions, setSelectedFunctions] = useState<any>(
		params.functions ? params.functions : ''
	);

	useEffect(() => {
		const _options: Promise<QueryType> = apiFetch({
			path: `/minos/v2/getposts`,
			method: 'POST',
			data: {
				args: {
					post_type: 'teams',
					posts_per_page: 9,
					paged: page,
					with_image: true,
					taxs: {
						functions_teams: selectedFunctions,
					},
				},
			},
		});
		_options.then((result) => {
			const q = result;
			setMaxPage(q.max_num_pages);
			setPosts(q.posts);
			setResults(q.found_posts);
		});
	}, [selectedFunctions, page]);

	useEffect(() => {
		router.get(
			window.location.href,
			{
				functions: selectedFunctions,
			},
			{
				preserveState: true,
				replace: true,
			}
		);
	}, [selectedFunctions]);

	const FiltersProps: FiltersTeamsType = {
		functions,
		setSelectedFunctions,
		selectedFunctions,
		listingTeams,
	};

	let containerStyle = '';

	if (listingTeams.variant === 'block') {
		containerStyle = `rounded-minos-listing-teams-container w-11/12 mx-auto relative z-[11] ${
			sectionDecoration?.isSectionDecorated && sectionDecoration?.type === 'wave'
				? 'mt-7 mb-12 tablet:mt-12 tablet:mb-24 desktop:mb-32'
				: 'my-7 tablet:my-12'
		}`;
	}

	let headingStyle = '';
	let subHeadingStyle = '';
	if (listingTeams.headingVariant === 'center') {
		headingStyle = 'items-center';
		subHeadingStyle = 'flex-col items-center';
	} else if (listingTeams.headingVariant === 'centerInline') {
		headingStyle = 'items-center';
		subHeadingStyle = 'flex-col tablet:w-full tablet:flex-row tablet:justify-between items-center';
	} else {
		headingStyle = 'items-start';
		subHeadingStyle = 'w-full justify-between items-center';
	}

	let paddingHeading = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		listingTeams.variant === 'headline'
	) {
		paddingHeading = 'pt-12 pb-12 tablet:pb-28 desktop:pb-32';
	} else {
		paddingHeading = 'pt-12 pb-0 tablet:py-12';
	}

	let marginHeading = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		listingTeams.variant === 'headline'
	) {
		marginHeading = 'mb-12 tablet:mb-24';
	} else {
		marginHeading = 'mb-12';
	}

	let paddingPagination = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		listingTeams.variant != 'block'
	) {
		paddingPagination = 'pb-20 desktop:pb-36';
	} else {
		paddingPagination = 'pb-12';
	}

	const viewProps: ViewProps = {
		sectionDecoration,
		listingTeams,
		containerStyle,
		marginHeading,
		paddingHeading,
		post,
		headingStyle,
		subHeadingStyle,
		results,
		filtersSelection,
		FiltersProps,
		posts,
		blockTeams,
		paddingPagination,
		page,
		setPage,
		maxPage,
	};

	return (
		<Layout
			seo={null}
			site={site}
			menus={menus}
			minos={minos}
			translations={translations}
			className={listingTeams.variant === 'block' ? 'bg-minos-listing-teams-secondary-color' : ''}
		>
			<View {...viewProps} />
		</Layout>
	);
}

type ViewProps = {
	sectionDecoration: SectionDecorationOptions;
	listingTeams: TeamsListingOptions;
	filtersSelection: TeamsFiltersChoices;
	blockTeams: TeamsOptions;
	post: PostType;
	posts: PostType[];
	FiltersProps: any;
	containerStyle: string;
	marginHeading: string;
	paddingHeading: string;
	headingStyle: string;
	subHeadingStyle: string;
	paddingPagination: string;
	results: number;
	page: number;
	maxPage: number;
	setPage: (value: number) => void;
};

const View = (props: ViewProps) => {
	const {
		sectionDecoration,
		listingTeams,
		containerStyle,
		marginHeading,
		paddingHeading,
		post,
		headingStyle,
		subHeadingStyle,
		results,
		filtersSelection,
		FiltersProps,
		posts,
		blockTeams,
		paddingPagination,
		page,
		setPage,
		maxPage,
	} = props;
	return (
		<>
			{/* Bubble decoration */}
			{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
				<>
					<Bubble
						position='left'
						className='minos-decoration-bubble-left top-[-50px]'
						pathClassName='fill-minos-decoration-bubble-left'
					/>
				</>
			)}
			<article
				data-listing={`listing-layout-${listingTeams.variant}`}
				id={post.ID.toString()}
				className={`${containerStyle} bg-minos-listing-teams-bg-color`}
			>
				<div
					data-heading={`listing-heading-${listingTeams.headingVariant}`}
					className={`minos-grid relative  ${marginHeading}  ${
						listingTeams.variant === 'headline' ? 'bg-minos-listing-teams-secondary-color' : ''
					}`}
				>
					<div
						className={`${headingStyle} relative z-[11] col-start-2 col-end-14 flex flex-col ${paddingHeading}`}
					>
						<h1 className='minos-listing-teams-heading-title-font-style mb-4 text-4xl tablet:minos-listing-teams-heading-title-font-style tablet:mb-8'>
							{listingTeams.listingHeadingTitle ? listingTeams.listingHeadingTitle : __('Listing Teams')}
						</h1>

						<div className={`${subHeadingStyle} mb-4 flex flex-wrap gap-4 tablet:gap-8`}>
							{results && results > 0 ? (
								<p className='minos-listing-teams-result-font-style text-base tablet:minos-listing-teams-result-font-style'>
									{results} {__('results')}
								</p>
							) : (
								<p className='minos-listing-teams-result-font-style text-base tablet:minos-listing-teams-result-font-style'>
									{results} {__('result')}
								</p>
							)}

							{filtersSelection.functions && <Filters {...FiltersProps} />}
						</div>
					</div>

					{/* Decoration wave visible only if variant listing === heading */}
					{listingTeams.variant === 'headline' && (
						<>
							{sectionDecoration.isSectionDecorated && sectionDecoration.type === 'wave' ? (
								<Wave
									className='absolute bottom-0 left-0 z-10 h-[17px] w-screen overflow-x-hidden tablet:h-[40px] desktop:h-[70px]'
									pathClassName='fill-minos-listing-teams-bg-color'
								/>
							) : (
								''
							)}
						</>
					)}
				</div>
				<section className='minos-grid'>
					<div className='z-[11] col-start-2 col-end-14 grid grid-cols-4 gap-y-5 tablet:grid-cols-12 tablet:gap-8'>
						{posts && posts.length > 0 ? (
							<>
								{posts.map((post: QueryPost) => (
									<CardsTypes
										key={post.ID}
										post={post}
										variant={blockTeams.variant}
										imgType={blockTeams.imgType}
										displayTags={blockTeams.displayTags}
										displayDesc={blockTeams.displayDesc}
										displayMail={blockTeams.displayMail}
										displayPhone={blockTeams.displayPhone}
										displaySocials={blockTeams.displaySocials}
										socialColor={blockTeams.socialColor}
										socialColorHover={blockTeams.socialColorHover}
									/>
								))}

								<Pagination
									className={paddingPagination}
									variant={listingTeams.paginationVariant}
									page={page}
									setPage={setPage}
									maxPage={maxPage}
								/>
							</>
						) : (
							<div className='minos-listing-teams-result-font-style col-span-12 my-10 text-center text-base tablet:minos-listing-teams-result-font-style'>
								{__('No post found :( ...')}
							</div>
						)}
					</div>
					{listingTeams.variant != 'block' && (
						<>
							{/* Bubble decoration */}
							{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
								<>
									<Bubble
										position='right'
										className='minos-decoration-bubble-right'
										pathClassName='fill-minos-decoration-bubble-right'
									/>
								</>
							)}
						</>
					)}
				</section>
			</article>
			{listingTeams.variant === 'block' && (
				<>
					{/* Bubble decoration */}
					{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
						<>
							<Bubble
								position='right'
								className='minos-decoration-bubble-right'
								pathClassName='fill-minos-decoration-bubble-right'
							/>
						</>
					)}
				</>
			)}
		</>
	);
};
