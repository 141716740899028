import type { ContentClass, PostSingleVariations } from '@/_types/Shared/Common';
import type { PostType } from '@/_types/Shared/Post';

type SingleSuperpositionProps = {
	post: PostType;
	variant?: PostSingleVariations;
	singleHeadingFontClassName?: ContentClass;
	displayAuthor?: boolean;
	displayDate?: boolean;
	displayTag?: boolean;
};

export default function SingleSuperposition(props: SingleSuperpositionProps) {
	const {
		post,
		variant = 'superposition',
		singleHeadingFontClassName,
		displayAuthor,
		displayDate,
		displayTag,
	} = props;

	return (
		<div
			data-variant={`single-${variant}`}
			className={`relative bg-minos-single-news-bg-color py-16`}
		>
			<div
				aria-hidden
				className='absolute left-0 top-0 z-0 h-[70%] w-full bg-minos-single-news-secondary-color tablet:h-[45%]'
			></div>

			<div className='minos-grid relative z-[11]' data-aos='fade-up'>
				<div className='relative z-[11] col-span-12 col-start-2 tablet:col-span-10 tablet:col-start-3'>
					<h1 className={`mb-3 tablet:mb-6 desktop:mb-7 ${singleHeadingFontClassName?.title}`}>
						{post.post_title}
					</h1>

					<div className={`mb-3 tablet:mb-6 desktop:mb-7 ${singleHeadingFontClassName?.text?.p}`}>
						{displayDate && (
							<>
								{post.post_date && (
									<>
										<time className='' dateTime={post?.post_date}>
											{post?.date_format}
										</time>

										{displayAuthor || displayTag ? (
											<>
												<span aria-hidden> | </span>
											</>
										) : (
											''
										)}
									</>
								)}
							</>
						)}

						{displayTag && (
							<>
								{post.categories?.length ? (
									<ul className='inline-flex'>
										{post.categories?.map((tag: string, index: number) => (
											<li key={index}> {(index ? ', ' : '') + tag} </li>
										))}
									</ul>
								) : (
									''
								)}
							</>
						)}

						{displayAuthor && (
							<>
								{post.author && (
									<>
										{(displayDate && displayTag) || displayTag ? (
											<>
												<span aria-hidden> | </span>
											</>
										) : (
											''
										)}
										<span>{post.author}</span>
									</>
								)}
							</>
						)}
					</div>

					{post.image && post.image.url ? (
						<img
							className='aspect-[5/3] w-full rounded-minos-single-news-img object-cover'
							src={post.image.url}
							alt={post.image.alt ? post.image.alt : post.image.title}
						/>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
}
