import { __Front as __ } from '@Shared/lib';

import type { SearchPageOptions } from '@/_types/Search/Search';
import type { QueryPost } from '@/_types/Shared/Query';
import { Link } from '@inertiajs/react';

import ReadMoreLabel from './ReadMoreLabel';
import SocialsTeams from './SocialsTeams';

type SearchCardProps = {
	posts: QueryPost[];
	searchOptions: SearchPageOptions;
};

export default function CardsTypes(props: SearchCardProps) {
	const { posts, searchOptions } = props;

	let cardClass =
		'bg-minos-search-card-bg-color border-2 rounded-minos-search-card-border-radius border-minos-search-card-border-color';
	let cardFlex = 'flex-col tablet:flex-row';

	if (searchOptions.cardVariant === 'underline') {
		cardClass = 'border-t-2 border-minos-search-card-underline-color';
	}

	if (searchOptions.cardImagePosition === 'left') {
		cardFlex = 'flex-col-reverse tablet:flex-row-reverse';
	}

	return (
		<>
			{posts.map((post: QueryPost) => (
				<Link
					key={post.ID}
					href={post.permalink ? post.permalink : ''}
					className={`group flex w-full gap-8 p-3 tablet:p-4 desktop:p-6 ${cardClass} ${cardFlex}`}
				>
					<div className='flex grow flex-col gap-3'>
						<p className='minos-search-card-infos-font-style flex flex-wrap gap-2 tablet:minos-search-card-infos-font-style'>
							{post.post_type === 'teams' &&
								searchOptions.cardDisplayTag &&
								post.tag &&
								post.tag.length > 0 && (
									<>
										{post.tag.map((tag: string, index: number) => (
											<>
												{index > 0 && <span> · </span>}
												<span key={index}> {tag} </span>
											</>
										))}
										<span> · </span>
									</>
								)}

							<PostType type={post.post_type} />

							{post.post_type === 'event' && searchOptions.cardDisplayEventDate && (
								<>
									<span> · </span>
									<time className='' dateTime={post?.start_date}>
										{post?.start_date_format}
									</time>
								</>
							)}

							{post.post_type === 'post' && searchOptions.cardDisplayNewsDate && (
								<>
									<span> · </span>
									<time className='' dateTime={post?.post_date}>
										{post?.date_format}
									</time>
								</>
							)}

							{post.post_type === 'page' && searchOptions.cardDisplayPagesDate && (
								<>
									<span> · </span>
									<time className='' dateTime={post?.post_date}>
										{post?.date_format}
									</time>
								</>
							)}

							{post.post_type != 'teams' &&
								searchOptions.cardDisplayTag &&
								post.tag &&
								post.tag.length > 0 && (
									<>
										<span> · </span>
										{post.tag.map((tag: string, index: number) => (
											<>
												{index > 0 && <span> · </span>}
												<span key={index}> {tag} </span>
											</>
										))}
									</>
								)}
						</p>
						<h2 className='minos-search-card-title-font-style tablet:minos-search-card-title-font-style'>
							{post.post_title}
						</h2>

						{post.post_type === 'teams' && (
							<div className='flex flex-wrap justify-between gap-4'>
								<SocialsTeams
									socials={post.socials}
									emailInfos={post.emailInfos}
									phoneInfos={post.phoneInfos}
								/>
							</div>
						)}

						{searchOptions.cardDisplayExcerpt && post.post_excerpt && (
							<p className='minos-search-card-excerpt-font-style tablet:minos-search-card-excerpt-font-style'>
								{post.post_excerpt}
							</p>
						)}

						<ReadMoreLabel
							readMoreHasIcon={searchOptions.cardReadMoreHasIcon}
							readMoreDecorationElement={searchOptions.cardReadMoreDecorationElement}
							contentFontClass='minos-search-card-readMore-font-style text-base tablet:minos-search-card-readMore-font-style'
						/>
					</div>
					{searchOptions.cardDisplayImage && post.image?.url && (
						<div className='tablet:max-w-56'>
							<img
								className='aspect-[5/3] w-full rounded-minos-search-card-image-border-radius object-cover'
								src={post.image?.url}
								alt={post.post_title}
							/>
						</div>
					)}
				</Link>
			))}
		</>
	);
}

const PostType = ({ type }: { type: string }) => {
	if (type === 'post') {
		type = __('Article');
	} else if (type === 'event') {
		type = __('Event');
	}

	return <span>{type}</span>;
};
