import { type PostType } from '@Types/Shared/Post';
import { type QueryPost, type QueryType } from '@Types/Shared/Query';
import { type TermType } from '@Types/Shared/Taxonomy';

import { useEffect, useState } from 'react';

import apiFetch from '@wordpress/api-fetch';

import Layout from '@Shared/Layout/Layout';
import { __Front as __, parseURL } from '@Shared/lib';

import type { NewsOptions } from '@/_types/Blocks/News';
import type { ListingOptions } from '@/_types/Shared/advanced-block-page/CommonListing';
import type {
	NewsFiltersChoices,
	NewsListingOptions,
} from '@/_types/Shared/advanced-block-page/PostListing';
import type { BtnOptions } from '@/_types/Shared/Button';
import type { SectionDecorationOptions } from '@/_types/Shared/Common';
import type { Options } from '@/_types/Shared/Options';
import type { SEO } from '@/_types/Shared/Seo';
import { type MenusType, type SiteType } from '@/_types/Shared/SiteDatas';
import { Bubble, Wave } from '@/views/Shared/Icons';
import type { Translation } from '@/views/Shared/Translations';
import { router } from '@inertiajs/react';

import CardsTypes from './components/CardsTypes';
import { Filters } from './components/Filters';
import { Pagination } from './components/Pagination';

type ListingNewsProps = {
	post: PostType;
	tags: TermType[];
	categories: TermType[];
	site: SiteType;
	menus: MenusType;
	minos: Options;
	translations: Translation[];
	seo: SEO;
};

export type FiltersPostType = {
	categories: TermType[];
	tags: TermType[];
	selectedCategorie: string;
	selectedTag: string;
	setSelectedCategorie: (e: string) => void;
	setSelectedTag: (e: string) => void;
	filtersSelection: NewsFiltersChoices;
	resetButtonStyle?: BtnOptions;
	listingNews: NewsListingOptions;
};

export default function Listing(props: ListingNewsProps) {
	const { post, tags, categories, site, menus, minos, translations, seo } = props;

	const listingNews: ListingOptions = { ...minos.layout.news.index };
	const blockNews: NewsOptions = { ...minos.blocks.news.default };
	const filtersSelection: NewsFiltersChoices = { ...minos.layout.news.index.listingFiltersChoices };
	const sectionDecoration: SectionDecorationOptions = { ...minos.layout.sectionDecoration };

	const params = parseURL(window.location.href);

	const [page, setPage] = useState<number>(params.pg ? params.pg : 1);
	const [posts, setPosts] = useState<PostType[]>([]);
	const [maxPage, setMaxPage] = useState<number>(1);
	const [results, setResults] = useState<number>(0);

	const [selectedTag, setSelectedTag] = useState<string>(params.post_tag ? params.post_tag : '');
	const [selectedCategorie, setSelectedCategorie] = useState<string>(
		params.categorie ? params.categorie : ''
	);

	useEffect(() => {
		const _options: Promise<QueryType> = apiFetch({
			path: `/minos/v2/getposts`,
			method: 'POST',
			data: {
				args: {
					post_type: 'post',
					posts_per_page: 9,
					paged: page,
					taxs: {
						relation: 'AND',
						post_tag: selectedTag,
						category: selectedCategorie,
					},
					with_image: true,
				},
			},
		});
		_options.then((result) => {
			const q = result;
			setMaxPage(q.max_num_pages);
			setPosts(q.posts);
			setResults(q.found_posts);
		});
	}, [selectedCategorie, selectedTag, page]);

	useEffect(() => {
		router.get(
			window.location.href,
			{
				categorie: selectedCategorie,
				post_tag: selectedTag,
			},
			{
				preserveState: true,
				replace: true,
			}
		);
	}, [selectedCategorie, selectedTag]);

	const FiltersProps = {
		tags,
		categories,
		setSelectedCategorie,
		setSelectedTag,
		selectedCategorie,
		selectedTag,
		filtersSelection: filtersSelection,
		listingNews,
		resetButtonStyle: listingNews.resetButtonStyle,
	};

	let containerStyle = '';

	if (listingNews.variant === 'block') {
		containerStyle = `rounded-minos-listing-news-container w-11/12 mx-auto relative z-[11] ${
			sectionDecoration?.isSectionDecorated && sectionDecoration?.type === 'wave'
				? 'mt-7 mb-12 tablet:mt-12 tablet:mb-24 desktop:mb-32'
				: 'my-7 tablet:my-12'
		}`;
	}

	let headingStyle = '';
	let subHeadingStyle = '';
	if (listingNews.headingVariant === 'center') {
		headingStyle = 'items-center';
		subHeadingStyle = 'flex-col items-center';
	} else if (listingNews.headingVariant === 'centerInline') {
		headingStyle = 'items-center';
		subHeadingStyle = 'flex-col tablet:w-full tablet:flex-row tablet:justify-between items-center';
	} else {
		headingStyle = 'items-start';
		subHeadingStyle = 'w-full justify-between items-center';
	}

	let paddingHeading = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		listingNews.variant === 'headline'
	) {
		paddingHeading = 'pt-12 pb-12 tablet:pb-28 desktop:pb-32';
	} else {
		paddingHeading = 'pt-12 pb-0 tablet:py-12';
	}

	let marginHeading = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		listingNews.variant === 'headline'
	) {
		marginHeading = 'mb-12 tablet:mb-24';
	} else {
		marginHeading = 'mb-12';
	}

	let paddingPagination = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		listingNews.variant != 'block'
	) {
		paddingPagination = 'pb-20 desktop:pb-36';
	} else {
		paddingPagination = 'pb-12';
	}

	const viewProps: ViewProps = {
		sectionDecoration,
		listingNews,
		post,
		containerStyle,
		marginHeading,
		headingStyle,
		paddingHeading,
		subHeadingStyle,
		results,
		filtersSelection,
		FiltersProps,
		posts,
		blockNews,
		paddingPagination,
		page,
		setPage,
		maxPage,
	};

	return (
		<Layout
			seo={seo}
			site={site}
			menus={menus}
			minos={minos}
			translations={translations}
			className={
				listingNews.variant === 'block' ? 'relative bg-minos-listing-news-secondary-color' : ''
			}
		>
			<View {...viewProps} />
		</Layout>
	);
}
type ViewProps = {
	sectionDecoration: SectionDecorationOptions;
	listingNews: ListingOptions;
	filtersSelection: NewsFiltersChoices;
	blockNews: NewsOptions;
	post: PostType;
	posts: PostType[];
	FiltersProps: FiltersPostType;
	containerStyle: string;
	marginHeading: string;
	paddingHeading: string;
	headingStyle: string;
	subHeadingStyle: string;
	paddingPagination: string;
	results: number;
	page: number;
	maxPage: number;
	setPage: (value: number) => void;
};

const View = (props: ViewProps) => {
	const {
		sectionDecoration,
		listingNews,
		filtersSelection,
		blockNews,
		post,
		containerStyle,
		marginHeading,
		headingStyle,
		paddingHeading,
		subHeadingStyle,
		results,
		FiltersProps,
		posts,
		paddingPagination,
		page,
		setPage,
		maxPage,
	} = props;

	return (
		<>
			{/* Bubble decoration */}
			{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
				<>
					<Bubble
						position='left'
						className='minos-decoration-bubble-left top-[-50px]'
						pathClassName='fill-minos-decoration-bubble-left'
					/>
				</>
			)}
			<article
				data-layout={`listing-layout-${listingNews.variant}`}
				id={post.ID.toString()}
				className={`${containerStyle} bg-minos-listing-news-bg-color`}
			>
				<div
					data-heading={`listing-heading-${listingNews.headingVariant}`}
					className={`minos-grid relative ${marginHeading} ${
						listingNews.variant === 'headline' ? 'bg-minos-listing-news-secondary-color' : ''
					}`}
				>
					<div
						className={`${headingStyle} relative z-[11] col-start-2 col-end-14 flex flex-col ${paddingHeading}`}
					>
						<h1 className='minos-listing-news-heading-title-font-style mb-4 text-4xl tablet:minos-listing-news-heading-title-font-style tablet:mb-8'>
							<>
								{listingNews.listingHeadingTitle ? listingNews.listingHeadingTitle : __('Listing Posts')}
							</>
						</h1>

						<div className={`${subHeadingStyle} mb-4 flex flex-wrap gap-4 tablet:gap-8`}>
							{results && results > 0 ? (
								<p className='minos-listing-news-result-font-style text-base tablet:minos-listing-news-result-font-style'>
									{results} {__('results')}
								</p>
							) : (
								<p className='minos-listing-news-result-font-style text-base tablet:minos-listing-news-result-font-style'>
									{results} {__('result')}
								</p>
							)}

							{filtersSelection.categories || filtersSelection.tags ? <Filters {...FiltersProps} /> : ''}
						</div>
					</div>

					{/* Wave Decoration only visible if variant listing === heading */}
					{listingNews.variant === 'headline' && (
						<>
							{sectionDecoration.isSectionDecorated && sectionDecoration.type === 'wave' ? (
								<Wave
									className='absolute bottom-0 left-0 z-10 h-[17px] w-screen overflow-x-hidden tablet:h-[40px] desktop:h-[70px]'
									pathClassName='fill-minos-listing-news-bg-color'
								/>
							) : (
								''
							)}
						</>
					)}
				</div>
				<section className='minos-grid relative'>
					<div className='z-[11] col-start-2 col-end-14 grid grid-cols-4 gap-y-5 tablet:grid-cols-12 tablet:gap-8'>
						{posts && posts.length > 0 ? (
							<>
								{posts.map((post: QueryPost) => (
									<CardsTypes
										key={post.ID}
										post={post}
										cardType={blockNews.cardType}
										textLabel={listingNews.cardTextLabel ? listingNews.cardTextLabel : __('Read More')}
										readMoreHasIcon={blockNews.readMoreHasIcon ? blockNews.readMoreHasIcon : false}
										readMoreDecorationElement={
											blockNews.readMoreDecorationElement ? blockNews.readMoreDecorationElement : 'short'
										}
										variant={blockNews.variant}
										displayExcerpt={blockNews.displayExcerpt}
										displayDate={blockNews.displayDate}
										displayTag={blockNews.displayTag}
									/>
								))}

								<Pagination
									className={paddingPagination}
									variant={listingNews.paginationVariant}
									page={page}
									setPage={setPage}
									maxPage={maxPage}
								/>
							</>
						) : (
							<div className='minos-listing-news-result-font-style col-span-12 my-10 text-center text-base tablet:minos-listing-news-result-font-style'>
								{__('No post found :( ...')}
							</div>
						)}
					</div>

					{listingNews.variant != 'block' && (
						<>
							{/* Bubble decoration */}
							{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
								<>
									<Bubble
										position='right'
										className='minos-decoration-bubble-right'
										pathClassName='fill-minos-decoration-bubble-right'
									/>
								</>
							)}
						</>
					)}
				</section>
			</article>

			{listingNews.variant === 'block' && (
				<>
					{/* Bubble decoration */}
					{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
						<>
							<Bubble
								position='right'
								className='minos-decoration-bubble-right'
								pathClassName='fill-minos-decoration-bubble-right'
							/>
						</>
					)}
				</>
			)}
		</>
	);
};
