import type { CardEventsType, EventsVariations } from '@/_types/Blocks/Events';
import { CardClassicEvents } from '@/App/Blocks/Events/Front/variants/Classic';
import { CardDateImgEvents } from '@/App/Blocks/Events/Front/variants/DateImg';
import { CardDateUpEvents } from '@/App/Blocks/Events/Front/variants/DateUp';
import { CardSuperpoEvents } from '@/App/Blocks/Events/Front/variants/Superposition';
import { CardTilteImgEvents } from '@/App/Blocks/Events/Front/variants/TitleImg';

type Card = CardEventsType & {
	variant: EventsVariations;
};

export default function CardsTypes(props: Card) {
	const {
		post,
		textLabel,
		cardType,
		readMoreHasIcon,
		readMoreDecorationElement,
		variant,
		iconType,
		displayExcerpt,
		displayDate,
		displayPlace,
		displayOrganizer,
		displayTag,
		displayPrice,
	} = props;

	const propsBlockEvents: CardEventsType = {
		post: post,
		textLabel: textLabel,
		cardType: cardType,
		readMoreHasIcon: readMoreHasIcon,
		readMoreDecorationElement: readMoreDecorationElement,
		iconType: iconType,
		displayExcerpt: displayExcerpt,
		displayDate: displayDate,
		displayPlace: displayPlace,
		displayOrganizer: displayOrganizer,
		displayTag: displayTag,
		displayPrice: displayPrice,
		contentFontClass: {
			eventTitle:
				'minos-events-inside-title-font-style text-2xl   tablet:minos-events-inside-title-font-style',
			eventExcerpt:
				'minos-events-inside-description-font-style text-base tablet:minos-events-inside-description-font-style',
			eventInfo: 'minos-events-info-font-style text-sm tablet:minos-events-info-font-style',
			eventReadMore:
				'minos-events-readMore-font-style text-base tablet:minos-events-readMore-font-style',
			eventDate: 'minos-events-date-font-style text-sm tablet:minos-events-date-font-style',
		},
	};

	const blockEventsVariations = {
		classic: <CardClassicEvents {...propsBlockEvents} />,
		dateImg: <CardDateImgEvents {...propsBlockEvents} />,
		dateUp: <CardDateUpEvents {...propsBlockEvents} />,
		titleImg: <CardTilteImgEvents {...propsBlockEvents} />,
		superposition: <CardSuperpoEvents {...propsBlockEvents} />,
	};

	return <>{blockEventsVariations[variant]}</>;
}
