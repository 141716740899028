import type { Decorations } from '@Types/Shared/Button';

import { ArrowBig, ArrowSmall } from '@Shared/Icons';
import { __Front as __ } from '@Shared/lib';

type ReadMoreType = {
	readMoreHasIcon: boolean | undefined;
	readMoreDecorationElement: Decorations | undefined;
	contentFontClass?: string;
};

export default function ReadMoreLabel(props: ReadMoreType) {
	const { readMoreHasIcon = false, readMoreDecorationElement, contentFontClass } = props;

	let underline = '';
	if (readMoreHasIcon && readMoreDecorationElement === 'underline') {
		underline =
			'w-fit after:block after:content-[""] after:absolute after:h-[3px] after:w-full after:scale-x-0 after:group-hover:scale-x-100 after:transition after:duration-300 after:origin-left after:bottom-0 after:bg-minos-search-card-readMore-color group-hover:after:bg-minos-search-card-readMore-color-hover inline-block';
	}

	return (
		<div
			className={`relative mt-auto inline-flex items-center gap-2 transition-all duration-300 ease-in-out group-hover:text-minos-search-card-readMore-color-hover ${underline} ${contentFontClass}`}
		>
			<span>{__('Read more')}</span>
			{readMoreHasIcon ? (
				<>
					{readMoreDecorationElement === 'long' && (
						<ArrowBig
							width='20'
							className='transition-transform group-hover:translate-x-2 group-hover:duration-300 group-hover:ease-in-out'
						/>
					)}

					{readMoreDecorationElement === 'short' && (
						<ArrowSmall
							width='20'
							className='transition-transform group-hover:translate-x-2 group-hover:duration-300 group-hover:ease-in-out'
						/>
					)}
				</>
			) : (
				''
			)}
		</div>
	);
}
