import { useState } from 'react';

import date from 'date-and-time';

import { __Front as __ } from '@Shared/lib';

import { Popover, PopoverContent, PopoverTrigger } from '@Blocks/_components/ui/popover';

import Filter from '@/views/Shared/Filters/Filter';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRange } from 'react-date-range';

import type { DatesType } from '@/_types/Blocks/Events';
import { ResetBtn } from '@/views/Shared/Button/ResetBtn';

import type { FiltersEventsType } from '../Listing';

export function Filters(props: FiltersEventsType) {
	const {
		venues,
		organizers,
		types,
		setSelectedVenues,
		setSelectedOrganizers,
		setSelectedTypes,
		setSelectedStartDate,
		setSelectedEndDate,
		selectedVenues,
		selectedOrganizers,
		selectedTypes,
		selectedStartDate,
		selectedEndDate,
		selectView,
		filtersSelection,
		resetButtonStyle,
		listingEvents,
	} = props;

	const VenuesFilter = {
		terms: venues,
		noResultLabel: __('No venue found'),
		searchLabel: __('Search for venues'),
		selectLabel: __('Select a venue'),
		setValue: setSelectedVenues,
		value: selectedVenues,
		selectClass:
			'minos-listing-events-filters-font-style bg-minos-listing-events-filters-bg-color border-2 border-minos-listing-events-filters-border-color text-base tablet:minos-listing-events-filters-font-style rounded-minos-events-filters-radius',
		actifClass: 'bg-minos-listing-events-filters-actif-color',
	};

	const OrganizersFilter = {
		terms: organizers,
		noResultLabel: __('No organizer found'),
		searchLabel: __('Search for organisers'),
		selectLabel: __('Select an organiser'),
		setValue: setSelectedOrganizers,
		value: selectedOrganizers,
		selectClass:
			'minos-listing-events-filters-font-style bg-minos-listing-events-filters-bg-color border-2 border-minos-listing-events-filters-border-color text-base tablet:minos-listing-events-filters-font-style rounded-minos-events-filters-radius',
		actifClass: 'bg-minos-listing-events-filters-actif-color',
	};

	const TypesFilter = {
		terms: types,
		noResultLabel: __('No type found'),
		searchLabel: __('Search for types'),
		selectLabel: __('Select a type'),
		setValue: setSelectedTypes,
		value: selectedTypes,
		selectClass:
			'minos-listing-events-filters-font-style bg-minos-listing-events-filters-bg-color border-2 border-minos-listing-events-filters-border-color text-base tablet:minos-listing-events-filters-font-style rounded-minos-events-filters-radius',
		actifClass: 'bg-minos-listing-events-filters-actif-color',
	};

	const dateClass =
		'minos-listing-events-filters-font-style bg-minos-listing-events-filters-bg-color border-2 border-minos-listing-events-filters-border-color tablet:minos-listing-events-filters-font-style rounded-minos-events-filters-radius';
	const [open, setOpen] = useState(false);

	const updateDateRange = (value: any) => {
		let startDate = null;
		let endDate = null;

		if (value.selection.endDate) {
			endDate = date.format(value.selection.endDate, 'YYYY-MM-DD');
		} else {
			endDate = '';
		}

		if (value.selection.startDate) {
			startDate = date.format(value.selection.startDate, 'YYYY-MM-DD');
		} else {
			startDate = '';
		}

		setSelectedStartDate(startDate);
		setSelectedEndDate(endDate);
	};

	const ResetSelectedDates = () => {
		setSelectedStartDate('');
		setSelectedEndDate('');
	};

	// TODO : START STATE TO DISCUSS
	const selectionRange = {
		startDate: selectedStartDate ? new Date(selectedStartDate) : new Date(),
		endDate: selectedEndDate ? new Date(selectedEndDate) : new Date(),
		key: 'selection',
		showDateDisplay: false,
	};

	let style = '';
	if (listingEvents?.headingVariant != 'leftInline') {
		style = 'block text-center tablet:flex tablet:flex-wrap tablet:items-center tablet:gap-4';
	} else {
		style = 'flex flex-wrap items-center gap-4';
	}

	let margin = '';
	if (listingEvents?.headingVariant != 'leftInline') {
		margin = 'mt-4 tablet:mt-0';
	} else {
		margin = '';
	}

	return (
		<ul className={`my-4 ${style}`}>
			{filtersSelection.venues && (
				<li className='w-full tablet:w-fit'>
					<Filter {...VenuesFilter} />
				</li>
			)}
			{filtersSelection.organizers && (
				<li className={`w-full tablet:w-fit ${margin}`}>
					<Filter {...OrganizersFilter} />
				</li>
			)}
			{filtersSelection.types && (
				<li className={`w-full tablet:w-fit ${margin}`}>
					<Filter {...TypesFilter} />
				</li>
			)}
			{selectView != 'calendar' && filtersSelection.dates && (
				<li className={`w-full tablet:w-fit ${margin}`}>
					<Popover open={open} onOpenChange={setOpen}>
						<PopoverTrigger asChild>
							<button
								role='combobox'
								id='combo-list-event'
								aria-controls='combo-list-event-content'
								aria-expanded={open}
								className={`${dateClass} flex items-center justify-between overflow-hidden px-4 py-3 tablet:w-[200px]`}
							>
								{__('Select a date')}
								<svg
									width='14'
									height='9'
									viewBox='0 0 14 9'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
									className={`transition-all ${open ? 'rotate-180' : 'rotate-0'}`}
								>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M7.00005 8.91421L0.292941 2.20711L1.70715 0.792894L7.00005 6.08579L12.2929 0.792895L13.7072 2.20711L7.00005 8.91421Z'
										fill='currentColor'
									/>
								</svg>
							</button>
						</PopoverTrigger>
						<PopoverContent
							role='listbox'
							id='combo-list-event-content'
							aria-labelledby='combo-list-event'
							className='border-0 bg-transparent p-0 shadow-none outline-0'
						>
							<DateRange
								color='#000000'
								rangeColors={['#000000']}
								ranges={[selectionRange]}
								onChange={(value: DatesType) => updateDateRange(value)}
							/>
						</PopoverContent>
					</Popover>
				</li>
			)}

			{selectedTypes ||
			selectedOrganizers ||
			selectedVenues ||
			selectedStartDate ||
			selectedEndDate ? (
				<li className={`w-full tablet:w-fit ${margin}`}>
					<ResetBtn
						className='minos-listing-events-reset-btn text-base tablet:minos-listing-events-reset-btn'
						btnHasIcon={resetButtonStyle?.btnHasIcon}
						btnDecorationElement={resetButtonStyle?.btnDecorationElement}
						animation={resetButtonStyle?.animation}
						resetSelected={() => {
							setSelectedVenues('');
							setSelectedTypes('');
							setSelectedOrganizers('');
							ResetSelectedDates();
						}}
					/>
				</li>
			) : (
				''
			)}
		</ul>
	);
}
