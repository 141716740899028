import { type TermType } from '@Types/Shared/Taxonomy';

import { useState } from 'react';

import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from '@Blocks/_components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@Blocks/_components/ui/popover';

type FilterType = {
	terms: TermType[];
	searchLabel: string;
	selectLabel: string;
	noResultLabel: string;
	setValue: (e: string) => void;
	value: string;
	selectClass?: string;
	actifClass?: string;
};

export default function Filter(props: FilterType) {
	const {
		terms,
		searchLabel,
		selectLabel,
		noResultLabel,
		setValue,
		value,
		selectClass = '',
		actifClass = '',
	} = props;
	const [open, setOpen] = useState(false);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<button
					role='combobox'
					id='combo-list1'
					aria-controls='combo-list1-content'
					aria-expanded={open}
					className={`${selectClass} flex w-full items-center justify-between gap-3 overflow-hidden px-4 py-3 tablet:min-w-[200px]`}
				>
					{value ? terms.find((term) => term.slug === value)?.name : selectLabel}
					<svg
						width='14'
						height='9'
						viewBox='0 0 14 9'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						aria-hidden='true'
						className={`transition-all ${open ? 'rotate-180' : 'rotate-0'}`}
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M7.00005 8.91421L0.292941 2.20711L1.70715 0.792894L7.00005 6.08579L12.2929 0.792895L13.7072 2.20711L7.00005 8.91421Z'
							fill='currentColor'
						/>
					</svg>
				</button>
			</PopoverTrigger>
			<PopoverContent
				role='listbox'
				id='combo-list1-content'
				aria-labelledby='combo-list1'
				className='w-[200px] p-0'
			>
				<Command>
					<CommandInput placeholder={searchLabel} />
					<CommandEmpty>{noResultLabel}</CommandEmpty>
					<CommandGroup>
						{terms.map((term) => (
							<CommandItem
								key={term.term_id}
								value={term.slug}
								onSelect={(currentValue: string) => {
									setValue(currentValue === value ? '' : currentValue);
									setOpen(false);
								}}
								className={`${value === term.slug ? actifClass : 'bg-transparent'}`}
							>
								{term.name} ({term.count})
							</CommandItem>
						))}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
