import type { ResetBtnType } from '@Types/Shared/Button';

import { motion } from 'framer-motion';

import { ArrowBig, ArrowSmall } from '@Shared/Icons';
import { __Front as __ } from '@Shared/lib';

export function ResetBtn(props: ResetBtnType) {
	const {
		btnHasIcon = false,
		btnDecorationElement = 'long',
		resetSelected = '',
		className = '',
		animation,
	} = props;

	const classes: string[] = [];

	// Animation with framer-motion
	let variants;
	let transition;

	const none = {
		variants: {
			rest: {},
			hover: {},
			pressed: {},
		},
		transition: {},
	};

	const scale = {
		variants: {
			rest: { scale: 1 },
			hover: { scale: 1.15 },
			pressed: { scale: 1.05 },
		},
		transition: {
			type: 'spring',
			damping: 5,
			stiffness: 75,
			restDelta: 0.001,
		},
	};

	const slideup = {
		variants: {
			rest: {},
			hover: { y: -10 },
			pressed: {},
		},
		transition: {
			type: 'tween',
			ease: 'circOut',
		},
	};

	const shake = {
		variants: {
			rest: { rotate: [0, 0, 0, 0] },
			hover: { rotate: [0, 3, -3, 0] },
			pressed: {},
		},
		transition: {
			repeat: 2,
			ease: 'linear',
			duration: 0.2,
		},
	};

	if (animation) {
		switch (animation) {
			case 'scale':
				variants = scale.variants;
				transition = scale.transition;
				break;

			case 'slideup':
				variants = slideup.variants;
				transition = slideup.transition;
				break;

			case 'shake':
				variants = shake.variants;
				transition = shake.transition;
				break;

			default:
				variants = none.variants;
				transition = none.transition;
				break;
		}
	}

	let styleZoom = '';
	if (animation === 'scale') {
		styleZoom = 'px-4';
	}

	classes.push(
		`group relative text-base inline-block transition-colors last:mr-0 w-fit mt-auto inline-flex items-center justify-center gap-2 ${className} ${styleZoom}`
	);

	return (
		<>
			<motion.a
				variants={variants}
				initial='rest'
				whileHover='hover'
				whileTap='pressed'
				transition={transition}
				tabIndex={0}
				role='button'
				className={`${classes.join(' ')}`}
				onClick={() => {
					resetSelected();
				}}
			>
				<span
					className={`${btnHasIcon} ${btnDecorationElement === 'underline' ? 'underline underline-offset-4' : ''}`}
				>
					{__('Reset')}
				</span>
				{btnHasIcon && (
					<>
						{btnDecorationElement === 'long' && (
							<ArrowBig
								width='20'
								className='transition-transform group-hover:translate-x-2 group-hover:duration-300 group-hover:ease-in-out'
							/>
						)}

						{btnDecorationElement === 'short' && (
							<ArrowSmall
								width='20'
								className='transition-transform group-hover:translate-x-2 group-hover:duration-300 group-hover:ease-in-out'
							/>
						)}
					</>
				)}
			</motion.a>
		</>
	);
}
