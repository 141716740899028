import type { ContentClass, PostSingleVariations } from '@/_types/Shared/Common';
import type { PostType } from '@/_types/Shared/Post';

type SingleWideProps = {
	post: PostType;
	variant?: PostSingleVariations;
	singleHeadingFontClassName?: ContentClass;
	displayAuthor?: boolean;
	displayDate?: boolean;
	displayTag?: boolean;
};

export default function SingleWide(props: SingleWideProps) {
	const {
		post,
		variant = 'classic',
		singleHeadingFontClassName,
		displayAuthor,
		displayDate,
		displayTag,
	} = props;

	return (
		<div
			data-variant={`single-${variant}`}
			className={`relative z-[11] col-start-2 col-end-13 rounded-b-minos-single-news-img bg-minos-single-news-bg-color sm:col-end-14`}
		>
			<h1
				className={`mx-auto mb-4 text-center tablet:mb-6 desktop:max-w-3xl ${singleHeadingFontClassName?.title}`}
			>
				{post.post_title}
			</h1>

			<div
				className={`mx-auto mb-5 text-center tablet:mb-6 desktop:mb-12 desktop:max-w-3xl ${singleHeadingFontClassName?.text?.p}`}
			>
				{displayDate && (
					<>
						{post.post_date && (
							<>
								<time className='' dateTime={post?.post_date}>
									{post?.date_format}
								</time>

								{displayAuthor || displayTag ? (
									<>
										<span aria-hidden> | </span>
									</>
								) : (
									''
								)}
							</>
						)}
					</>
				)}

				{displayTag && (
					<>
						{post.categories?.length ? (
							<ul className='inline-flex'>
								{post.categories?.map((tag: string, index: number) => (
									<li key={index}> {(index ? ', ' : '') + tag} </li>
								))}
							</ul>
						) : (
							''
						)}
					</>
				)}

				{displayAuthor && (
					<>
						{post.author && (
							<>
								{(displayDate && displayTag) || displayTag ? (
									<>
										<span aria-hidden> | </span>
									</>
								) : (
									''
								)}
								<span>{post.author}</span>
							</>
						)}
					</>
				)}
			</div>

			{post.image && post.image.url ? (
				<img
					className='aspect-[5/3] w-full rounded-minos-single-news-img object-cover'
					src={post.image.url}
					alt={post.image.alt ? post.image.alt : post.image.title}
				/>
			) : (
				''
			)}
		</div>
	);
}
