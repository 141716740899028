import { CardAlternateTeams } from '@/App/Blocks/Teams/Front/variants/Alternate';
import { CardBlockTeams } from '@/App/Blocks/Teams/Front/variants/Block';
import { CardClassicTeams } from '@/App/Blocks/Teams/Front/variants/Classic';
import { CardHorizontalTeams } from '@/App/Blocks/Teams/Front/variants/Horizontal';
import type { CardTeamsType, TeamsVariations } from '@Types/Blocks/Teams';

type Card = CardTeamsType & {
	variant: TeamsVariations;
};

export default function CardsTypes(props: Card) {
	const { 
		post,
		imgType,
		displayTags,
		displayDesc,
		displayMail,
		displayPhone,
		displaySocials,
		socialColor,
		socialColorHover,
		variant,
		single
	} = props;

	const propsBlockTeams: CardTeamsType = {
		post: post,
		imgType: imgType,
		displayTags: displayTags,
		displayDesc: displayDesc,
		displayMail: displayMail,
		displayPhone: displayPhone,
		displaySocials: displaySocials,
		socialColor: socialColor,
		socialColorHover: socialColorHover,
		contentFontClass: {
			teamsTitle:
				'minos-teams-inside-title-font-style text-2xl  tablet:minos-teams-inside-title-font-style ',
			teamsExcerpt:
				'minos-teams-inside-content-font-style text-base tablet:minos-teams-inside-content-font-style',
			teamsFunctions:
				'minos-teams-function-font-style text-base tablet:minos-teams-function-font-style',
		},
		single: single,
	};

	const blockTeamsVariations = {
		classic: <CardClassicTeams {...propsBlockTeams} />,
		block: <CardBlockTeams {...propsBlockTeams} />,
		alternate: <CardAlternateTeams {...propsBlockTeams} />,
		horizontal: <CardHorizontalTeams  {...propsBlockTeams} />,
	};

	return <>{blockTeamsVariations[variant]}</>;
}
