import type { eventTimeInfo } from '@/_types/Shared/advanced-block-page/EventSingle';
import type {
	ContainerTypeEnums,
	ContentClass,
	IconTypeEnums,
	PostSingleVariations,
} from '@/_types/Shared/Common';
import type { QueryPost } from '@/_types/Shared/Query';

import EventsInfo from '../components/EventsInfo';

type SingleWideProps = {
	post: QueryPost;
	variant?: PostSingleVariations;
	singleHeadingFontClassName?: ContentClass;
	dates?: eventTimeInfo[];
	iconType?: IconTypeEnums;
	eventsInfoContainerType?: ContainerTypeEnums;
	displayOrganizer?: boolean;
	displayDate?: boolean;
	displayTag?: boolean;
	displayPrice?: boolean;
	displayPlace?: boolean;
	displaySite?: boolean;
};

export default function SingleWide(props: SingleWideProps) {
	const {
		post,
		variant = 'classic',
		singleHeadingFontClassName,
		dates,
		eventsInfoContainerType,
		iconType,
		displayDate,
		displayOrganizer,
		displayPlace,
		displayPrice,
		displayTag,
		displaySite,
	} = props;

	let containerStyle = 'mt-6 tablet:mt-12';
	if (eventsInfoContainerType === 'block') {
		containerStyle =
			'mt-6 tablet:mt-12 rounded-minos-single-events-info border-2 bg-minos-single-events-info-bg-color border-minos-single-events-info-border-color p-3 tablet:p-6';
	} else {
		containerStyle = 'mt-6 tablet:mt-12';
	}

	return (
		<div
			data-variant={`single-${variant}`}
			className={`relative z-[11] col-start-2 col-end-13 bg-minos-single-events-bg-color sm:col-end-14`}
		>
			<h1
				className={`mx-auto mb-4 text-center tablet:mb-6 desktop:max-w-3xl ${singleHeadingFontClassName?.title}`}
			>
				{post.post_title}
			</h1>

			{post.image && post.image.url ? (
				<img
					className='aspect-[5/3] w-full rounded-minos-single-events-img object-cover'
					src={post.image.url}
					alt={post.image.alt ? post.image.alt : post.image.title}
				/>
			) : (
				''
			)}

			{/* Events Info */}
			{displayDate || displayOrganizer || displayPlace || displayPrice || displayTag || displaySite ? (
				<>
					<EventsInfo
						post={post}
						dates={dates}
						singleHeadingFontClassName={singleHeadingFontClassName}
						iconType={iconType}
						containerStyle={containerStyle}
						eventsInfoContainerType={eventsInfoContainerType}
						displayDate={displayDate}
						displayOrganizer={displayOrganizer}
						displayPlace={displayPlace}
						displayPrice={displayPrice}
						displayTag={displayTag}
						displaySite={displaySite}
					/>
				</>
			) : (
				''
			)}
		</div>
	);
}
