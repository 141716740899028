type InfosType = {
	socials?: string[];
	emailInfos: any;
	phoneInfos: any;
};

export default function SocialsTeams(props: InfosType) {
	const { socials, emailInfos, phoneInfos } = props;

	const socialsClass =
		'minos-search-social-size text-base tablet:minos-search-social-size hover:text-minos-search-social-color-hover';
	const infosClass =
		'flex w-full gap-1.5 items-center minos-search-card-infos-font-style text-base tablet:minos-search-card-infos-font-style hover:text-minos-search-social-color-hover';

	return (
		<>
			<div className='flex flex-col gap-2.5'>
				{emailInfos && emailInfos.email && (
					<>
						<a
							href={'mailto:' + emailInfos.email}
							className={infosClass}
							target='_blank'
							rel='noreferrer'
						>
							{emailInfos.icon && <i className={emailInfos.icon}></i>}
							<span>{emailInfos.email}</span>
						</a>
					</>
				)}
				{phoneInfos && phoneInfos.phone && (
					<>
						<a href={'tel:' + phoneInfos.phone} className={infosClass} target='_blank' rel='noreferrer'>
							{phoneInfos.icon && <i className={phoneInfos.icon}></i>}
							<span>{phoneInfos.phone}</span>
						</a>
					</>
				)}
			</div>
			<div className='flex gap-2.5'>
				{socials && socials?.length > 0 && (
					<>
						{socials.map((social: any, i: number) => (
							<a key={i} href={social.url} className={socialsClass} target='_blank' rel='noreferrer'>
								{social.icon && <i className={social.icon}></i>}
							</a>
						))}
					</>
				)}
			</div>
		</>
	);
}
