import { Fragment, useContext } from 'react';

import Columns from '@Blocks/Columns/Front/Register';
import Editor from '@Blocks/Editor/Front/Register';
import Events from '@Blocks/Events/Front/Register';
import FactFigure from '@Blocks/Fact-figure/Front/Register';
import Faqs from '@Blocks/Faqs/Front/Register';
import Form from '@Blocks/Form/Front/Register';
import Hero from '@Blocks/Hero/Front/Register';
import Image from '@Blocks/Image/Front/Register';
import News from '@Blocks/News/Front/Register';
import Shortcode from '@Blocks/Shortcode/Front/Block';
import TxtImg from '@Blocks/Txt_img/Front/Register';
import Video from '@Blocks/Video/Front/Register';

import Teams from '@/App/Blocks/Teams/Front/Register';

import { MinosContext } from '../Context';

export default function Blocks({ blocks }: { blocks: any[] }) {
	const MinosOptions = useContext(MinosContext);
	const _blocks = blocks.filter((block) => block.block != null);

	return (
		<>
			{_blocks.map((block, index) => {
				block.placement = {
					order: index,
					max: blocks.length,
				};

				return (
					<Fragment key={index}>
						{block.block === 'minos/hero' && (
							<Hero
								block={block}
								variant={MinosOptions.blocks.hero.default.variant}
								containerType={MinosOptions.blocks.hero.default.containerType}
							/>
						)}

						{block?.block === 'minos/columns' && (
							<Columns
								block={block}
								variant={MinosOptions.blocks.column.default.variant}
								containerType={MinosOptions.blocks.column.default.containerType}
								cardType={MinosOptions.blocks.column.default.cardType}
								headingAlign={MinosOptions.blocks.column.default.headingAlign}
							/>
						)}

						{block.block === 'minos/image' && (
							<Image
								block={block}
								variant={MinosOptions.blocks.image.default.variant}
								containerType={MinosOptions.blocks.image.default.containerType}
								headingAlign={MinosOptions.blocks.image.default.headingAlign}
							/>
						)}

						{block.block === 'minos/video' && (
							<Video
								block={block}
								variant={MinosOptions.blocks.video.default.variant}
								containerType={MinosOptions.blocks.video.default.containerType}
								headingAlign={MinosOptions.blocks.video.default.headingAlign}
							/>
						)}

						{block.block === 'minos/editor' && (
							<Editor
								block={block}
								variant={MinosOptions.blocks.editor.default.variant}
								containerType={MinosOptions.blocks.editor.default.containerType}
								headingAlign={MinosOptions.blocks.editor.default.headingAlign}
								wrapperType={MinosOptions.blocks.editor.default.wrapperType}
								quoteStyle={MinosOptions.blocks.editor.default.quoteStyle}
								tableStyle={MinosOptions.blocks.editor.default.tableStyle}
							/>
						)}

						{block.block === 'minos/txtimg' && (
							<TxtImg
								block={block}
								variant={MinosOptions.blocks.txtImg.default.variant}
								containerType={MinosOptions.blocks.txtImg.default.containerType}
								headingAlign={MinosOptions.blocks.txtImg.default.headingAlign}
							/>
						)}

						{block.block === 'minos/shortcode' && <Shortcode block={block} />}

						{block.block === 'minos/news' && (
							<News
								block={block}
								variant={MinosOptions.blocks.news.default.variant}
								containerType={MinosOptions.blocks.news.default.containerType}
								cardType={MinosOptions.blocks.news.default.cardType}
								headingAlign={MinosOptions.blocks.news.default.headingAlign}
								readMoreHasIcon={MinosOptions.blocks.news.default.readMoreHasIcon}
								readMoreDecorationElement={MinosOptions.blocks.news.default.readMoreDecorationElement}
							/>
						)}

						{block.block === 'minos/events' && (
							<Events
								block={block}
								variant={MinosOptions.blocks.events.default.variant}
								containerType={MinosOptions.blocks.events.default.containerType}
								cardType={MinosOptions.blocks.events.default.cardType}
								headingAlign={MinosOptions.blocks.events.default.headingAlign}
								readMoreHasIcon={MinosOptions.blocks.events.default.readMoreHasIcon}
								readMoreDecorationElement={MinosOptions.blocks.events.default.readMoreDecorationElement}
								iconType={MinosOptions.blocks.events.default.iconType}
							/>
						)}

						{block.block === 'minos/fact-figure' && (
							<FactFigure
								block={block}
								variant={MinosOptions.blocks.factFigure.default.variant}
								containerType={MinosOptions.blocks.factFigure.default.containerType}
								wrapperType={MinosOptions.blocks.factFigure.default.wrapperType}
								headingAlign={MinosOptions.blocks.factFigure.default.headingAlign}
								factFigureAlign={MinosOptions.blocks.factFigure.default.factFigureAlign}
								factStyle={MinosOptions.blocks.factFigure.default.factStyle}
							/>
						)}

						{block.block === 'minos/faqs' && (
							<Faqs
								block={block}
								variant={MinosOptions.blocks.faqs.default.variant}
								containerType={MinosOptions.blocks.faqs.default.containerType}
								headingAlign={MinosOptions.blocks.faqs.default.headingAlign}
							/>
						)}

						{block.block === 'minos/teams' && (
							<Teams
								block={block}
								variant={MinosOptions.blocks.teams.default.variant}
								containerType={MinosOptions.blocks.teams.default.containerType}
								imgType={MinosOptions.blocks.teams.default.imgType}
								headingAlign={MinosOptions.blocks.teams.default.headingAlign}
							/>
						)}

						{block.block === 'minos/form' && (
							<Form
								block={block}
								containerType={MinosOptions.blocks.form.default.containerType}
								wrapperType={MinosOptions.blocks.form.default.wrapperType}
								headingAlign={MinosOptions.blocks.form.default.headingAlign}
							/>
						)}
					</Fragment>
				);
			})}
		</>
	);
}
