import { type PostType } from '@Types/Shared/Post';
import { type QueryType } from '@Types/Shared/Query';

import { useEffect, useState } from 'react';

import apiFetch from '@wordpress/api-fetch';

import Layout from '@Shared/Layout/Layout';
import { __Front as __, parseURL } from '@Shared/lib';

import type { SearchFiltersChoices, SearchPageOptions } from '@/_types/Search/Search';
import type { BtnOptions } from '@/_types/Shared/Button';
import type { SectionDecorationOptions } from '@/_types/Shared/Common';
import type { Options } from '@/_types/Shared/Options';
import type { MenusType, SiteType } from '@/_types/Shared/SiteDatas';
import { Bubble, Wave } from '@/views/Shared/Icons';
import type { Translation } from '@/views/Shared/Translations';
import { router } from '@inertiajs/react';

import CardsTypes from './components/CardsTypes';
import { Filters } from './components/Filters';
import { Pagination } from './components/Pagination';

type ListingSearch = {
	query: string;
	cpts: Record<string, any>;
	site: SiteType;
	menus: MenusType;
	minos: Options;
	translations: Translation[];
};

export type FiltersCpts = {
	cpts: Record<string, any>;
	selectedCpts: string;
	setSelectedCpts: (e: string) => void;
	resetButtonStyle?: BtnOptions;
	pageSearch?: SearchPageOptions;
};

type ViewProps = {
	sectionDecoration: SectionDecorationOptions;
	pageSearch: SearchPageOptions;
	containerStyle: string;
	marginHeading: string;
	paddingHeading: string;
	headingStyle: string;
	subHeadingStyle: string;
	results: number;
	query: string;
	FiltersProps: FiltersCpts;
	filtersSelection: SearchFiltersChoices;
	padding: string;
	posts: PostType[];
	page: number;
	minos: Options;
	setPage: (value: number) => void;
	maxPage: number;
};

export default function Search(props: ListingSearch) {
	const { query, cpts, site, menus, minos, translations } = props;

	const params = parseURL(window.location.href);

	const pageSearch: SearchPageOptions = { ...minos.layout.search };
	const filtersSelection: SearchFiltersChoices = { ...minos.layout.search.listingFiltersChoices };
	const sectionDecoration: SectionDecorationOptions = { ...minos.layout.sectionDecoration };

	const [page, setPage] = useState<number>(params.pg ? params.pg : 1);
	const [posts, setPosts] = useState<PostType[]>([]);
	const [maxPage, setMaxPage] = useState<number>(1);
	const [results, setResults] = useState<number>(0);

	const [selectedCpts, setSelectedCpts] = useState<any>(params.types ? params.types : '');

	let postType: string[] | string = [];

	useEffect(() => {
		if (selectedCpts === '') {
			postType = ['post', 'page', 'event', 'teams'];
		} else {
			postType = selectedCpts;
		}
	}, [selectedCpts]);

	useEffect(() => {
		const _options: Promise<QueryType> = apiFetch({
			path: `/minos/v2/getposts`,
			method: 'POST',
			data: {
				args: {
					post_type: postType,
					paged: page,
					s: query,
					posts_per_page: 12,
					with_image: true,
				},
			},
		});
		_options.then((result) => {
			const q = result;
			setMaxPage(q.max_num_pages);
			setPosts(q.posts);
			setResults(q.found_posts);
		});
	}, [query, page, selectedCpts]);

	useEffect(() => {
		router.get(
			window.location.href,
			{
				types: selectedCpts,
			},
			{
				preserveState: true,
				replace: true,
			}
		);
	}, [selectedCpts]);

	const FiltersProps = {
		cpts,
		setSelectedCpts,
		selectedCpts,
		pageSearch,
		resetButtonStyle: pageSearch.resetButtonStyle,
	};

	let margin = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration.type === 'wave' &&
		pageSearch.variant === 'block'
	) {
		margin = 'mt-7 mb-20 tablet:mt-12 desktop:mb-40';
	} else {
		margin = 'my-7 tablet:my-12';
	}

	let containerStyle = '';
	if (pageSearch.variant === 'block') {
		containerStyle = `rounded-minos-search-container w-11/12 mx-auto relative z-[11] ${margin}`;
	}

	let headingStyle = '';
	let subHeadingStyle = '';
	if (pageSearch.headingVariant === 'center') {
		headingStyle = 'items-center';
		subHeadingStyle = 'flex-col items-center';
	} else if (pageSearch.headingVariant === 'centerInline') {
		headingStyle = 'items-center';
		subHeadingStyle = 'flex-col tablet:w-full tablet:flex-row tablet:justify-between items-center';
	} else {
		headingStyle = 'items-start';
		subHeadingStyle =
			'flex-col tablet:flex-row w-full justify-between items-start tablet:items-center';
	}

	let padding = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration.type === 'wave' &&
		pageSearch.variant != 'block'
	) {
		padding = 'pb-16 tablet:pb-24 desktop:pb-40';
	} else {
		padding = '';
	}

	let marginHeading = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		pageSearch.variant === 'headline'
	) {
		marginHeading = 'mb-12 tablet:mb-24';
	} else {
		marginHeading = 'mb-12';
	}

	let paddingHeading = '';
	if (
		sectionDecoration?.isSectionDecorated &&
		sectionDecoration?.type === 'wave' &&
		pageSearch.variant === 'headline'
	) {
		paddingHeading = 'pt-12 pb-12 tablet:pb-28 desktop:pb-32';
	} else {
		paddingHeading = 'pt-12 pb-0 tablet:py-12';
	}

	const viewProps: ViewProps = {
		sectionDecoration,
		pageSearch,
		containerStyle,
		marginHeading,
		paddingHeading,
		headingStyle,
		subHeadingStyle,
		results,
		query,
		FiltersProps,
		filtersSelection,
		padding,
		posts,
		page,
		minos,
		setPage,
		maxPage,
	};

	return (
		<Layout
			site={site}
			menus={menus}
			minos={minos}
			className={pageSearch.variant === 'block' ? 'relative bg-minos-search-secondary-color' : ''}
			translations={translations}
		>
			<View {...viewProps} />
		</Layout>
	);
}

const View = (props: ViewProps) => {
	const {
		sectionDecoration,
		pageSearch,
		containerStyle,
		marginHeading,
		paddingHeading,
		headingStyle,
		subHeadingStyle,
		results,
		query,
		FiltersProps,
		filtersSelection,
		padding,
		posts,
		page,
		minos,
		setPage,
		maxPage,
	} = props;

	return (
		<>
			{/* Bubble decoration */}
			{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
				<>
					<Bubble
						position='left'
						className='minos-decoration-bubble-left top-[-50px]'
						pathClassName='fill-minos-decoration-bubble-left'
					/>
				</>
			)}
			<article
				data-layout={`search-${pageSearch.variant}`}
				className={`${containerStyle} bg-minos-search-bg-color`}
			>
				<div
					className={`minos-grid relative ${marginHeading} ${
						pageSearch.variant === 'headline' ? 'bg-minos-search-secondary-color' : ''
					}`}
				>
					<div
						data-heading={`search-heading-${pageSearch.headingVariant}`}
						className={`${headingStyle} relative z-[11] col-start-2 col-end-14 flex flex-col ${paddingHeading}`}
					>
						<h1 className='minos-search-heading-title-font-style mb-4 text-4xl tablet:minos-search-heading-title-font-style tablet:mb-8'>
							{__('Search')}
						</h1>

						<div
							data-aos='fade-up'
							className={`${subHeadingStyle} mb-4 flex flex-wrap gap-4 tablet:gap-8`}
						>
							{results && results > 0 ? (
								<>
									{results === 1 && (
										<p className='minos-search-result-font-style text-base tablet:minos-search-result-font-style'>
											{results} {__('Result for')} {query}
										</p>
									)}

									{results > 1 && (
										<p className='minos-search-result-font-style text-base tablet:minos-search-result-font-style'>
											{results} {__('Results for')} {query}
										</p>
									)}
								</>
							) : (
								<p className='minos-search-result-font-style text-base tablet:minos-search-result-font-style'>
									{results} {__('Result for')} {query}
								</p>
							)}

							{filtersSelection.cpts && <Filters {...FiltersProps} />}
						</div>
					</div>

					{/* Wave Decoration only visible if variant listing === heading */}
					{pageSearch.variant === 'headline' && (
						<>
							{sectionDecoration.isSectionDecorated && sectionDecoration.type === 'wave' ? (
								<Wave
									className='absolute bottom-0 left-0 z-10 h-[17px] w-screen overflow-x-hidden tablet:h-[40px] desktop:h-[70px]'
									pathClassName='fill-minos-search-bg-color'
								/>
							) : (
								''
							)}
						</>
					)}
				</div>
				<section className={`minos-grid relative ${padding}`}>
					<div
						data-aos='fade-up'
						className='z-[11] col-start-2 col-end-14 flex flex-col gap-y-5 tablet:col-span-12 tablet:col-start-2 tablet:gap-8'
					>
						{posts && posts.length > 0 ? (
							<>
								<CardsTypes posts={posts} searchOptions={minos.layout.search} />

								<Pagination
									variant={pageSearch.paginationVariant}
									page={page}
									setPage={setPage}
									maxPage={maxPage}
									classname='pb-12'
								/>
							</>
						) : (
							<div className='minos-search-result-font-style my-10 w-full text-center text-base tablet:minos-search-result-font-style'>
								{__('No post found :( ...')}
							</div>
						)}
					</div>
					{pageSearch.variant != 'block' && (
						<>
							{/* Bubble decoration */}
							{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
								<>
									<Bubble
										position='right'
										className='minos-decoration-bubble-right'
										pathClassName='fill-minos-decoration-bubble-right'
									/>
								</>
							)}
						</>
					)}
				</section>
			</article>

			{pageSearch.variant === 'block' && (
				<>
					{/* Bubble decoration */}
					{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
						<>
							<Bubble
								position='right'
								className='minos-decoration-bubble-right'
								pathClassName='fill-minos-decoration-bubble-right'
							/>
						</>
					)}
				</>
			)}
		</>
	);
};
