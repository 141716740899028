import type { CardFaqsType, FaqsVariations } from '@Types/Blocks/Faqs';

import { CardBlockFaqs } from '@/App/Blocks/Faqs/Front/variants/Block';
import { CardClassicFaqs } from '@/App/Blocks/Faqs/Front/variants/Classic';

type Card = CardFaqsType & {
	variant: FaqsVariations;
};

export default function CardsTypes(props: Card) {
	const { posts, variant } = props;

	const propsBlockFaqs: CardFaqsType = {
		posts: posts,
		contentFontClass: {
			title: 'minos-faqs-inside-title-font-style text-2xl  tablet:minos-faqs-inside-title-font-style',
			text: {
				p: 'minos-faqs-content-p text-base tablet:minos-faqs-content-p',
				h1: 'minos-faqs-content-h1 text-4xl tablet:minos-faqs-content-h1',
				h2: 'minos-faqs-content-h2 text-3xl tablet:minos-faqs-content-h2',
				h3: 'minos-faqs-content-h3 text-2xl tablet:minos-faqs-content-h3',
				h4: 'minos-faqs-content-h4 text-xl tablet:minos-faqs-content-h4',
				h5: 'minos-faqs-content-h5 text-xl tablet:minos-faqs-content-h5',
				h6: 'minos-faqs-content-h6 text-xl tablet:minos-faqs-content-h6',
			},
		},
	};

	const blockFaqsTypeFront = {
		classic: <CardClassicFaqs {...propsBlockFaqs} />,
		block: <CardBlockFaqs {...propsBlockFaqs} />,
	};

	return <>{blockFaqsTypeFront[variant]}</>;
}
