import type {
	PaginationListingType,
	PaginationVariations,
} from '@/_types/Shared/advanced-block-page/CommonListing';
import BgPagination from '@/views/Shared/Pagination/variants/Bg';
import UnderlinePagination from '@/views/Shared/Pagination/variants/Underline';

type PaginationProps = {
	page: number;
	setPage: any;
	maxPage: number;
	variant: PaginationVariations;
	className?: string;
};

export const Pagination = (props: PaginationProps) => {
	const { page, setPage, maxPage, variant, className } = props;

	const propsPaginationListingNews: PaginationListingType = {
		paginationFontClassName: {
			text:
				'minos-listing-news-pagination-font-style tablet:minos-listing-news-pagination-font-style group-hover:text-minos-listing-news-pagination-color-hover',
			arrow:
				'text-minos-listing-news-pagination-arrow-color group-hover:text-minos-listing-news-pagination-arrow-color-hover',
			bgCurrent:
				'rounded-minos-news-pagination-radius bg-minos-listing-news-pagination-bg-color group-hover:bg-minos-listing-news-pagination-bg-color-hover',
			bg: 'rounded-minos-news-pagination-radius group-hover:bg-minos-listing-news-pagination-bg-color-hover',
		},
		className: className,
	};

	const variationsPagination = {
		bg: (
			<BgPagination
				currentPage={page}
				setPage={setPage}
				maxPage={maxPage}
				{...propsPaginationListingNews}
			/>
		),
		underline: (
			<UnderlinePagination
				currentPage={page}
				setPage={setPage}
				maxPage={maxPage}
				{...propsPaginationListingNews}
			/>
		),
	};

	return <>{variationsPagination[variant]}</>;
};
