import type { QueryPost } from '@/_types/Shared/Query';
import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';
type DateCardType = {
	item: any;
	isDayDate: string;
	setIsDayDate: any;
};

export function DateCard(props: DateCardType) {
	const { item } = props;
	const events = item.events;

	const [isOpen, setIsOpen] = useState<boolean>(false);

	let past = '';
	let cardClass = ' bg-minos-listing-events-calendar-card-without-event-bg-color border-minos-listing-events-calendar-card-border-color rounded-minos-listing-events-calendar-card-border-radius minos-listing-events-calendar-card-border-width';
	let contentClass = ' minos-listing-events-calendar-card-content-font-style ';
	let dayBtnClass = ' minos-listing-events-calendar-card-day-cta-label-font-style bg-minos-listing-events-calendar-card-day-bg-color rounded-minos-listing-events-calendar-card-day-border-radius';

	if (item.class === 'past') {
		past = 'opacity-25 focus:pointer-events-none pointer-events-none';
	}
	
	if (events && events.length > 0) {
		cardClass = ' bg-minos-listing-events-calendar-card-with-event-bg-color border-minos-listing-events-calendar-card-with-event-border-color rounded-minos-listing-events-calendar-card-border-radius minos-listing-events-calendar-card-border-width'
		contentClass = ' minos-listing-events-calendar-card-content-font-style text-minos-listing-events-calendar-card-with-event-content-color'
		dayBtnClass = ' bg-minos-listing-events-calendar-cta-day-bg-color minos-listing-events-calendar-card-day-cta-label-font-style text-minos-listing-events-calendar-card-with-event-cta-day-color rounded-minos-listing-events-calendar-card-day-border-radius';
	}

	if (item.class === 'current') {
		contentClass = ' minos-listing-events-calendar-card-content-font-style ';
		cardClass = ' bg-minos-listing-events-calendar-current-day-bg-color border-minos-listing-events-calendar-current-day-border-color rounded-minos-listing-events-calendar-card-border-radius minos-listing-events-calendar-card-border-width';
		dayBtnClass= ' minos-listing-events-calendar-card-day-cta-label-font-style text-minos-listing-events-calendar-current-day-cta-day-color bg-minos-listing-events-calendar-current-day-cta-bg-color rounded-minos-listing-events-calendar-card-day-border-radius '
	}

	if (item.class === 'current' && events && events.length > 0) {
		contentClass = ' minos-listing-events-calendar-card-content-font-style text-minos-listing-events-calendar-current-day-content-color'
	}

	return (
		<div
			key={item.date}
			className={`${cardClass} ${past} dektop:items-start relative flex min-h-10 w-auto min-w-10 justify-center p-0 tablet:p-2.5 desktop:flex-col desktop:content-start desktop:justify-start desktop:text-left`}
		>
			{!events  && (
				<span className={`${dayBtnClass} place-self-center p-1 text-sm tablet:p-2 desktop:inline-block desktop:place-self-start`}>{item.day}</span>
			)}
			
			{events && events.length > 0 && (
				<Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
					<Dialog.Trigger className='flex w-fit'>
						<span aria-hidden='true' className={`${dayBtnClass} cursor-pointer place-self-center p-1 text-sm tablet:p-2 desktop:inline-block desktop:place-self-start`}>{item.day}</span>
						<span className='sr-only'>{item.dayBig}</span>
					</Dialog.Trigger>
					<Dialog.Overlay />
					<Dialog.Content>
						<div className='fixed left-1/4 top-1/2 z-20 w-1/2 rounded-minos-listing-events-calendar-modal-border-radius bg-minos-listing-events-calendar-modal-bg-color p-5 shadow-2xl tablet:absolute tablet:left-1/2 tablet:w-fit tablet:min-w-96 tablet:-translate-x-1/2 tablet:-translate-y-1/2'>
							<div className='flex justify-between'>
								<h3 className='minos-listing-events-calendar-modal-title-style'>{item.dayBig}</h3>
								<Dialog.Close className='minos-listing-events-calendar-modal-title-style'>X</Dialog.Close>
							</div>
							<ul className='flex flex-col gap-4'>
								{events.map((post: QueryPost) => (
									<li key={post.ID}>
										<a
											href={post.permalink ? post.permalink : '#'}
											className='minos-listing-events-calendar-modal-content-style text-left'
											>
											{post.post_title}
										</a>
									</li>
								))}
							</ul>
						</div>
					</Dialog.Content>
				</Dialog.Root>
			)}

			<div className='mt-3 hidden desktop:block'>
				{events && events.length > 0 && (
					<>
						<ul className='flex flex-col gap-4'>
							{events.map((post: QueryPost) => (
								<li key={post.ID}>
									<a href={post.permalink} className={contentClass}>{post.post_title}</a>
								</li>
							))}
						</ul>
					</>
				)}
			</div>
		</div>
	);
}