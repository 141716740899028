import { useEffect, type ReactElement } from 'react';

import AOS from 'aos';

import { Head } from '@inertiajs/react';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

import 'aos/dist/aos.css';

import defu from 'defu';

import type { Options } from '@/_types/Shared/Options';
import type { SEO } from '@/_types/Shared/Seo';
import { type MenusType, type SiteType } from '@/_types/Shared/SiteDatas';

import { defaultContext, MinosContext } from '../Context';
import GlobalStyles from '../GlobalStyles/GlobalStyles';
import { Translations, type Translation } from '../Translations';

export default function Layout({
	children,
	site,
	menus,
	minos,
	className,
	translations,
	seo,
}: {
	children: ReactElement;
	site: SiteType;
	menus: MenusType;
	minos: Options;
	className?: string;
	translations: Translation[];
	seo: SEO | null;
}) {
	useEffect(() => {
		AOS.init({
			once: true,
			duration: 500,
			offset: 220,
			easing: 'ease-out-sine',
			delay: 100,
		});
	}, []);

	const _translations = translations;

	const minosOptions = defu(minos, defaultContext) as Options;

	const HeaderData = {
		site: site,
		menus: menus,
		context: minosOptions.layout.header,
	};

	const FooterData = {
		site: site,
		menus: menus,
		context: minosOptions.layout.footer,
	};

	return (
		<>
			<Head>
				<title>{seo?.title ? site.name + ' - ' + seo.title : site.name}</title>
				<meta name='description' content={seo?.description ? seo.description : site.description}></meta>
				<link rel='canonical' href={window.location.href} />

				<meta property='og:title' content={seo?.title ? site.name + ' - ' + seo.title : site.name} />
				<meta
					property='og:description'
					content={seo?.description ? seo.description : site.description}
				/>
				<meta property='og:url' content={seo?.url ? seo.url : window.location.href} />
				<meta property='og:image' content={seo?.image && seo.image} />
			</Head>
			<Translations.Provider value={_translations}>
				<>
					<MinosContext.Provider value={minosOptions}>
						<GlobalStyles options={minos}>
							<div className='flex min-h-screen flex-col bg-minos-global-bg'>
								<Header {...HeaderData} />
								<main className={className}>{children}</main>
								<Footer minos={minosOptions.layout.sectionDecoration} {...FooterData} />
							</div>
						</GlobalStyles>
					</MinosContext.Provider>
				</>
			</Translations.Provider>
		</>
	);
}
