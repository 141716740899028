import { __Front as __ } from '@Shared/lib';

import type { Page404Options } from '@/_types/404/page404';
import type { SectionDecorationOptions } from '@/_types/Shared/Common';
import type { Options } from '@/_types/Shared/Options';
import type { MenusType, SiteType } from '@/_types/Shared/SiteDatas';
import { Button } from '@/views/Shared/Button/Button';
import { Bubble } from '@/views/Shared/Icons';
import type { Translation } from '@/views/Shared/Translations';

import Layout from '../../Shared/Layout/Layout';

type SingleEventsType = {
	site: SiteType;
	menus: MenusType;
	minos: Options;
	translations: Translation[];
};

export default function notFound(props: SingleEventsType) {
	const { site, menus, minos, translations } = props;

	const page404: Page404Options = { ...minos.layout.page404 };
	const sectionDecoration: SectionDecorationOptions = { ...minos.layout.sectionDecoration };

	let alignPositionStyle = '';
	if (page404.textAlign === 'center') {
		alignPositionStyle = 'm-auto text-center';
	} else if (page404.textAlign === 'left') {
		alignPositionStyle = 'mr-auto';
	} else {
		alignPositionStyle = 'ml-auto';
	}

	let containerStyle = '';
	if (page404.containerType === 'block') {
		containerStyle =
			'px-8 tablet:10 desktop:px-16 bg-minos-404-bg-container-color border-2 border-minos-404-container-border-color rounded-minos-404-container';
	} else {
		containerStyle = '';
	}

	let padding = '';
	if (sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'wave') {
		padding = 'pt-12 pb-16 tablet:pt-12 tablet:pb-24 desktop:pt-24 desktop:pb-40';
	} else {
		padding = 'py-5 tablet:py-12 desktop:py-24';
	}

	const Props: ViewProps = {
		sectionDecoration,
		containerStyle,
		site,
		page404,
		padding,
		alignPositionStyle,
	};

	return (
		<Layout site={site} translations={translations} menus={menus} minos={minos}>
			<View {...Props} />
		</Layout>
	);
}

type ViewProps = {
	sectionDecoration: SectionDecorationOptions;
	containerStyle: string;
	site: SiteType;
	page404: Page404Options;
	padding: string;
	alignPositionStyle: string;
};

const View = (props: ViewProps) => {
	const { sectionDecoration, containerStyle, site, page404, padding, alignPositionStyle } = props;
	return (
		<>
			<section className={`minos-grid bg-minos-404-bg-color ${padding}`}>
				{/* Bubble decoration */}
				{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
					<>
						<Bubble
							position='left'
							className='minos-decoration-bubble-left'
							pathClassName='fill-minos-decoration-bubble-left'
						/>
					</>
				)}

				<div
					className={`relative z-[11] col-start-2 col-end-14 py-10 tablet:py-24 desktop:py-72 ${containerStyle}`}
					data-aos='fade-up'
				>
					<div className={`block max-w-72 tablet:max-w-none ${alignPositionStyle}`}>
						<h1 className='minos-404-title-font-style text-4xl tablet:minos-404-title-font-style'>
							{__('404 Error')}
						</h1>
						<p className='minos-404-description-font-style my-5 text-base tablet:minos-404-description-font-style tablet:my-8'>
							{__('Sorry, this page is not found')}
						</p>
						<Button
							label={__('Back to Homepage')}
							url={site.url}
							style='primary'
							urlType='link'
							primaryClassname='minos-404-btn tablet:minos-404-btn'
							btnHasIcon={page404.btnStyle?.btnHasIcon}
							btnDecorationElement={page404.btnStyle?.btnDecorationElement}
							animation={page404.btnStyle?.animation}
						/>
					</div>
				</div>

				{/* Bubble decoration */}
				{sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'bubble' && (
					<>
						<Bubble
							position='right'
							className='minos-decoration-bubble-right'
							pathClassName='fill-minos-decoration-bubble-right'
						/>
					</>
				)}
			</section>
		</>
	);
};
