import { __Front as __ } from '@Shared/lib';

import { ResetBtn } from '@/views/Shared/Button/ResetBtn';
import Filter from '@/views/Shared/Filters/Filter';

import type { FiltersFaqsType } from '../Listing';

export const Filters = (props: FiltersFaqsType) => {
	const { types, setSelectedTypes, selectedTypes, resetButtonStyle, listingFaqs } = props;

	const TypesFilter = {
		terms: types,
		noResultLabel: __('No type found'),
		searchLabel: __('Search for types'),
		selectLabel: __('Select a type'),
		setValue: setSelectedTypes,
		value: selectedTypes,
		selectClass:
			'minos-listing-faqs-filters-font-style bg-minos-listing-faqs-filters-bg-color border-2 border-minos-listing-faqs-filters-border-color rounded-minos-faqs-filters-radius',
		actifClass: 'bg-minos-listing-faqs-filters-actif-color',
	};

	let style = '';
	if (listingFaqs.headingVariant != 'leftInline') {
		style = 'block text-center tablet:flex tablet:flex-wrap tablet:items-center tablet:gap-4';
	} else {
		style = 'flex flex-wrap items-center gap-4';
	}

	let margin = '';
	if (listingFaqs.headingVariant != 'leftInline') {
		margin = 'mt-4 tablet:mt-0';
	} else {
		margin = '';
	}

	return (
		<ul className={`my-4 ${style}`}>
			<li className='w-full tablet:w-fit'>
				<Filter {...TypesFilter} />
			</li>

			{selectedTypes && (
				<li className={`w-full tablet:w-fit ${margin}`}>
					<ResetBtn
						className='minos-listing-faqs-reset-btn tablet:minos-listing-faqs-reset-btn'
						btnHasIcon={resetButtonStyle?.btnHasIcon}
						btnDecorationElement={resetButtonStyle?.btnDecorationElement}
						animation={resetButtonStyle?.animation}
						resetSelected={() => {
							setSelectedTypes('');
						}}
					/>
				</li>
			)}
		</ul>
	);
};
