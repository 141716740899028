import { __Front as __ } from '@Shared/lib';

import { ResetBtn } from '@/views/Shared/Button/ResetBtn';

import type { FiltersCpts } from '../Search';
import Filter from './Filter';

export const Filters = (props: FiltersCpts) => {
	const { cpts, setSelectedCpts, selectedCpts, resetButtonStyle, pageSearch } = props;

	const CptsFilter = {
		terms: cpts,
		noResultLabel: __('No content type found'),
		searchLabel: __('Search for content types'),
		selectLabel: __('Select a content type'),
		setValue: setSelectedCpts,
		value: selectedCpts,
		selectClass:
			'minos-search-filters-font-style bg-minos-search-filters-bg-color border-2 border-minos-search-filters-border-color rounded-minos-search-filters-radius',
		actifClass: 'bg-minos-search-filters-actif-color',
	};

	let style = '';
	if (pageSearch?.headingVariant != 'leftInline') {
		style = 'block text-center tablet:flex tablet:flex-wrap tablet:items-center tablet:gap-4';
	} else {
		style = 'flex flex-wrap items-center gap-4';
	}

	let margin = '';
	if (pageSearch?.headingVariant != 'leftInline') {
		margin = 'mt-4 tablet:mt-0';
	} else {
		margin = '';
	}

	return (
		<ul className={`my-4 ${style}`}>
			<li className='w-full tablet:w-fit'>
				<Filter {...CptsFilter} />
			</li>
			{selectedCpts && (
				<li className={`w-full tablet:w-fit ${margin}`}>
					<ResetBtn
						className='minos-search-reset-btn tablet:minos-search-reset-btn'
						btnHasIcon={resetButtonStyle?.btnHasIcon}
						btnDecorationElement={resetButtonStyle?.btnDecorationElement}
						animation={resetButtonStyle?.animation}
						resetSelected={() => {
							setSelectedCpts('');
						}}
					/>
				</li>
			)}
		</ul>
	);
};
